.cookieinfo {
  display: flex !important;
  flex-direction: column-reverse;
  align-self: flex-end;
  left: 30px !important;
  bottom: 30px !important;
  width: 440px !important;
  padding: 3em 2em 1.8em !important;
  border-radius: 0 5px 5px 0;
  border: 4px solid #c9c9c9;
  box-shadow:0px 3px 6px 0px rgba($black, .16);
  background: $white !important;
  font-family: $base-font-family !important;
  @include transition;

  span {
    color: $base-font-color;
    font-size: 1.6rem !important;
    line-height: 1.8;
  }

  a {
    opacity: 1;
    position: relative;
    display: block;
    max-width: 170px;
    margin-top: 1.5em;
    padding-left: 1.5em;
    color: $marine-blue !important;
    font-weight: 500;

    &::before {
      position: absolute;
      top: .65em;
      left: 0;
      display: inline-block;
      color: $gray-darken-1;
      font-family: 'nssol-icon';
      font-size: 1.3rem;
      line-height: 1;
      content: "\e90b";
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  .cookieinfo-close {
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: flex-end;
    z-index: 10;
    width: 145px;
    height: 44px;
    margin-top: -2em;
    margin-left: 0 !important;
    border-radius: 4px !important;
    border: 1px solid $gray-darken-2 !important;
    background: $white !important;
    color: $base-font-color !important;
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    @include transition;
  }

  .cookieinfo-close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 44px;
    height: 44px;
    margin-top: 0;
    border: none !important;
    background: transparent !important;
    font-size: 24px !important;
    cursor: pointer;

    &::before {
      color: $gray-darken-1;
      font-family: "nssol-icon";
      content: "\e908";
    }

    span {
      @include sr-only;
    }

  }

  @include media-pc {

    a {
      &[href="/info/cookie.html"],
      &[href="/en/info/cookie.html"] {
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .cookieinfo-close {
      &:hover {
        background: rgba(black, .08) !important;
      }
    }

    .cookieinfo-close-icon {
      &:hover {
        background: transparent !important;
      }
    }
  }

  @include media-sp {
    left: 0 !important;
    bottom: 10px !important;
    width: 94% !important;
    margin: 0 auto;
    padding: 1.5em 1em 1em !important;
    border-radius: 5px;

    a {
      margin-top: 1em;

      &::before {
        top: .6em;
        font-size: 1.2rem;
      }
    }

    span {
      font-size: 1.3rem !important;
    }

    .cookieinfo-close {
      font-size: 1.3rem !important;
      height: 36px;
    }

    .cookieinfo-close-icon {
      top: 0;
      right: 0;
      font-size: 20px !important;
    }
  }
}