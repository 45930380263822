.c-tag {
  color: $cyan;
  font-weight: 700;
  text-decoration: underline;

  [class^="icon-"] {
    margin-left: 4px;

    &::before {
      color: $cyan;
    }
  }

  @include media-pc {
    &:hover {
      text-decoration: none;
    }
  }

  @include media-sp {
    font-size: 1.1rem;
  }
}