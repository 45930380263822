.c-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -1em -1em 0;

  &__item {
    padding: 1em;

    @for $grid-col from 1 through 12 {
      &.-col-#{$grid-col} {
        width: (100% * $grid-col / 12);
      }
    }
  }

  &.-wide {
    margin: -1.5em -1.5em 0;
    .c-grid__item {
      padding: 1.5em;
    }
  }

  @include media-sp {

    &__item {

      @for $grid-col from 1 through 12 {
        &.-col-sp-#{$grid-col} {
          width: (100% * $grid-col / 12);
        }
      }
    }
  }
}