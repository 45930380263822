.c-label-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.625em;

  > li {
    display: inline-flex;
    padding: 0.625em;
  }

  @include media-sp {
    margin: -5px;

    > li {
      padding: 5px;
    }
  }
}

.c-label {
  display: inline-block;
  min-width: 92px;
  padding: 9px 12px;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;

  &.-white {
    border: 1px solid $gray-darken-2;
    background: $white;
  }

  &.-yellow {
    background: #FFF7B5;
  }

  &.-blue {
    background: $cyan;
    color: $white;
  }

  &.-green {
    background: $green;
    color: $white;
  }

  @include media-pc {
    &.-large {
      min-width: 136px;
    }

    &.-small {
      font-size: 1.2rem;
    }
  }

  @include media-sp {
    min-width: 74px;
    min-height: 24px;
    padding: 6px;
    font-size: 1rem;
    vertical-align: top;

    &.-small {
      min-width: inherit;
    }
  }
}

.c-link-label {
  position: relative;
  overflow: hidden;
  display: inline-block;
  min-width: 92px;
  padding: 9px 12px;
  color: $gray-darken-2;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;

  &.-white {
    border: 1px solid #a0a0a0;
    background: $white;

    &.-current {
      background: #FFF7B5;
    }
  }

  @include media-pc {
    &.-white {

      &:hover {
        background: #FFF7B5;
      }
    }

    &.-large {
      min-width: 136px;
    }

    &.-small {
      font-size: 1.2rem;
    }
  }

  @include media-sp {
    min-width: 74px;
    padding: 6px;
    font-size: 1rem;

    &.-small {
      min-width: inherit;
    }
  }
}

.c-new-label {

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 52px 52px 0;
    border-color: transparent $light-yellow transparent transparent;
    content: "";
  }

  > span {
    display: block;
    position: absolute;
    top: 8px;
    right: 6px;
    z-index: 5;
    transform: rotate(45deg);
    font-size: 1.1rem;
  }

  @include media-sp {
    &::before {
      border-width: 0 42px 42px 0;
    }

    > span {
      top: 5px;
      right: 3px;
      font-size: 1rem;
    }
  }
}