.c-video {
  max-width: 640px;
  margin: 0 auto;

  &__item {
    position: relative;
    margin-bottom: 1em;
    padding-bottom: 56.25%;
  }

  &__btn {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 0;

    img {
      width: 100%;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.09);
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba($black, .5);
      content: "";
    }

    &::after {
      @include centering(true, true);
      z-index: 2;
      width: 64px;
      height: 64px;
      background: url(/assets/img/common/ico_play_circle_white.svg) no-repeat;
      background-size: contain;
      content: "";
    }
  }
}

.c-video-02 {
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;

  &__item {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.c-youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}