.c-link {
  display: inline-block;
  position: relative;
  color: $marine-blue;
  text-decoration: none;
  line-height: 1.6;

  @include media-pc {
    &:hover {
      text-decoration: underline;
    }
  }

  &.-arrow,
  &.-anchor {
    padding-left: 1.5em;

    &::before {
      position: absolute;
      top: .5em;
      left: 0;
      display: inline-block;
      color: $gray-darken-1;
      font-family: 'nssol-icon';
      font-size: 1.3rem;
      line-height: 1;
    }
  }

  &.-arrow {

    &::before {
      content: "\e90b";
    }
  }

  &.-anchor {
    padding-left: 1.75em;
    color: $base-font-color;

    &::before {
      content: "\e909";
    }
  }

  &.-large {
    font-size: 2.3rem;

    &::after {
      font-size: 1.8rem;
    }
  }

  &[target="_blank"],
  &[href$=".pdf"],
  &[href$=".zip"],
  &[href^="mailto:"],
  &.-open-new {
    &::after {
      display: inline-block;
      margin-left: .5em;
      color: #4A4A4A;
      font-family: 'nssol-icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: middle;
    }
  }

  &[target="_blank"],
  &.-open-new {
    &::after {
      vertical-align: baseline;
      content: "\e910";
    }
  }

  &[href$=".pdf"] {

    &::after {
      font-size: 1.9rem;
      content: "\e90d";
      vertical-align: middle;

      @include media-sp {
        font-size: 1.5rem;
      }
    }
  }

  &[href$=".zip"] {

    &::after {
      font-size: 1.9rem;
      content: "\e912";
      vertical-align: middle;

      @include media-sp {
        font-size: 1.5rem;
      }
    }
  }

  &[href^="mailto:"] {
    &::after {
      font-size: 1.9rem;
      content: "\e911";

      @include media-sp {
        font-size: 1.5rem;
      }
    }
  }

  @include media-sp {

    &.-arrow,
    &.-anchor {
      padding-left: 1.5em;

      &::before {
        top: .6em;
        font-size: 1.2rem;
      }
    }
    &.-anchor {
      &::before {
        top: .65em;
      }
    }

    &.-large {
      font-size: 1.6rem;

      &::after {
        font-size: 1.4rem;
      }
    }
  }
}

p > .c-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
  &[target="_blank"],
  &[href$=".pdf"],
  &[href$=".zip"],
  &[href^="mailto:"],
  &.-open-new {
    &::after {
      margin-left: .25em;
      margin-right: .5em;
    }
  }
}


.c-image-link {
  overflow: hidden;
  display: block;
  position: relative;

  &__text {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 220px;
    padding: 10px 32px 10px 24px;
    background: rgba($white, .9);
    font-weight: 500;
    text-align: center;
    @include transition;

    .icon-pdf {
      font-size: 1.8rem;
    }

    [class^="icon-"] {
      display: inline-block;
      overflow: hidden;
      @include centering(false, true);
      right: 11px;

      &::before {
        color: $gray-darken-1;
      }
    }

    &::before {
      color: $gray-darken-1;
      transform: translateX(0);
    }
  }

  &.-border {
    border: 1px solid #CECECE;
  }

  @include media-pc {
    img {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.09);
      }
      .c-image-link__text {
        background: $white;

        [class^="icon-"] {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }

    &.-no-link {
      &:hover {
        .c-image-link__text {
          background: rgba($white, .9);
        }
        img {
          transform: inherit;
        }
      }
    }
  }

  @include media-sp {
    &__text {
      min-width: 150px;
      padding: 6px 24px;
      font-size: 1.2rem;

      &::after {
        right: 10px;
      }

      [class^="icon-"] {
        right: 8px;
      }

      &.-sp-normal {
        position: relative;
        display: inline-block;
        margin-top: 5px;
        padding: 0 .5em 0 1.5em;
        background: transparent;
        color: $marine-blue;
        text-align: left;
        line-height: 1.4;

        [class^="icon-"] {
          position: absolute;
          top: 9px;
          left: 4px;
          font-size: 1rem;
        }
      }
    }
  }
}

.c-image-link-02 {
  $this: &;
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;

  &__image {
    position: relative;
    margin-bottom: 1em;
  }

  &__link {
    position: relative;
    overflow: hidden;
    display: block;
    position: relative;
    margin-bottom: 1em;

    &[target="_blank"],
    &[href$=".pdf"] {
      &::after {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 4;
        display: inline-block;
        color: $white;
        font-family: 'nssol-icon';
        line-height: 1;
        content: "\e910";
      }
    }

    &[target="_blank"] {
      &::after {
        content: "\e910";
      }
    }

    &[href$=".pdf"] {

      &::after {
        font-size: 1.9rem;
        content: "\e90d";

        @include media-sp {
          font-size: 1.5rem;
        }
      }
    }

    img {
      transition: all 0.2s ease-in-out;
    }
  }

  &.-light {
    #{$this}__link {
      &[target="_blank"],
      &[href$=".pdf"] {
        &::after {
          color: #111;
        }
      }
    }

    #{$this}__sub-title {
      color: #111;
      text-shadow: none;
    }
  }

  &__text {
    position: absolute;
    bottom: 20px;
    left: 25px;
    z-index: 10;
    padding-right: 1em;
  }

  &__title {
    margin-bottom: 10px;
    color: $white;
    font-size: 3.2rem;
    font-weight: 700;
    text-shadow: 0 0 4px rgba(1, 6, 69, 0.5);
    line-height: 1.3;
  }

  &__sub-title {
    color: $white;
    font-size: 1.9rem;
    font-weight: 500;
    text-shadow: 0 0 4px rgba(1, 6, 69, 0.5);
    line-height: 1.5;
  }

  @include media-pc {

    &__link {
      &:hover {
        img {
          transform: scale(1.07);
        }
      }
    }
  }

  @include media-sp {

    &__text {
      bottom: 15px;
      left: 15px;
    }

    &__title {

      font-size: 1.7rem;
    }

    &__sub-title {
      font-size: 1.4rem;
    }
  }
}
