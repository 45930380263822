.c-list {

  li {
    position: relative;

    &:not(:first-child) {
      margin-top: .75em;
    }

    > ul {
      margin-top: 1em;
    }
  }

  &.-circle {

    li {
      padding-left: 1.5em;

      &::before {
        position: absolute;
        top: .85em;
        left: .5em;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $base-font-color;
        content: "";

        @include media-sp {
          top: .8em;
        }
      }
    }
  }

  &.-square {
    li {
      padding-left: 1.5em;

      &::before {
        position: absolute;
        top: .8em;
        left: .5em;
        width: 5px;
        height: 5px;
        border-radius: 0;
        background: $cyan;
        content: "";

        @include media-tablet {
          top: .65em;
        }

        @include media-sp {
          top: .75em;
        }
      }
    }
  }

  &.-ordered {
    padding-left: 1.5em;
    list-style-type: decimal;

    >li {
      padding-left: .5em;
    }
  }

  &.-note {

    >li {
      padding-left: 1.5em;

      &::before {
        position: absolute;
        left: 0;
        content: '※';
      }
    }
  }

  &.-check {
    > li {
      padding-left: 28px;
      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        width: 20px;
        height: 16px;
        background: url(/assets/img/common/ico_check_blue.svg) no-repeat;
        background-size: contain;
        content: '';
      }

      @include media-sp {
        padding-left: 20px;

        &::before {
          top: 6px;
          width: 16px;
          height: 12px;
        }
      }
    }
  }

  &.-small {
    > li {
      font-size: 1.3rem;

      @include media-sp {
        font-size: 1.2rem;
      }
    }
  }

  &.-flex {
    display: flex;
    flex-wrap: wrap;

    >li {
      margin: 0 1.5em 1em 0;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}

.c-link-list {
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0 4em 1em 0;
  }

  @include media-sp {
    li {
      margin: 0 2em 1em 0;
    }
  }
}

.c-box {
  .c-link-list {
    li {
      margin: .5em 4em .5em 0;
    }

    @include media-sp {
      li {
        margin: .5em 2em .5em 0;
      }
    }
  }
}

@include media-sp {
  .c-grid {
    $this: &;
    #{$this}__item.-col-sp-12 {
      + #{$this}__item {
        .c-list {
          margin-top: -1em;
        }
      }
    }
  }
}

.c-btn-list {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  margin: -7px;

  &__item {
    width: 20%;
    padding: 7px;

    a {
      height: 100%;
    }
  }

  @include media-pc {
    &.-col-3 {
      #{$this}__item {
        width: 33.3%;
      }
    }
  }

  @include media-sp {
    &__item {
      width: 50%;
    }
  }
}

