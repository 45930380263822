.c-search-box {

  form {
    overflow: hidden;
    display: flex;
    border-radius: 6px;
    border: 1px solid #a0a0a0;
    background: $white;
  }

  &__input {
    width: 100%;
    height: 62px;
    padding: 20px;
    border: none;
    @include transition;

    &::placeholder {
      color: $gray-darken-2;
    }

    &:focus {
      background: #E8F8FE;
    }
  }

  &__search-btn {
    width: 80px;
    border-left: 1px solid #a0a0a0;
    background: $white;

    .icon-search {
      span {
        position: absolute;
        text-indent: -9999px;
      }

      &::before {
        color: $cyan;
        font-size: 2.2rem;
      }
    }
  }

  @include media-sp {

    &__input {
      height: 50px;
      padding: 12px;
    }

    &__search-btn {
      width: 44px;

      .icon-search {
        &::before {
          font-size: 1.8rem;
        }
      }
    }
  }
}