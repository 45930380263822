.c-section {
  max-width: $base-content-width;
  margin: 0 auto 64px;

  &.-narrow {
    max-width: 980px;
  }

  &.-small {
    margin-bottom: 48px;
  }

  &.-medium {
    margin-bottom: 72px;
  }

  &.-large {
    margin-bottom: 96px;
  }

  &.-xlarge {
    margin-bottom: 120px;
  }

  @include media-sp {
    margin-bottom: 40px;

    &.-small {
      margin-bottom: 32px;
    }

    &.-medium {
      margin-bottom: 48px;
    }

    &.-large {
      margin-bottom: 64px;
    }

    &.-xlarge {
      margin-bottom: 80px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
