/* l-gnav
----------------------------------------------------------- */
.l-gnav {
  width: 100%;
  margin-top: 22px;
  background: $white;

  &__list {
    display: flex;
    margin: 0 auto;
  }

  &__item {
    flex: auto;

    &:last-child {
      a {
        padding-right: 0;
        @include media-sp {
          padding-right: 10px;
        }
      }
    }
  }
  

  &__link {
    display: block;
    position: relative;
    padding: 6px 12px;
    color: $base-font-color;
    font-weight: 500;
    line-height: 1;
    @include transition;
  }

  &__expand-icon {
    display: block;
    font-family: 'nssol-icon';
    @include transition;

    &::before {
      color: $gray;
      font-size: 1rem;
    }
  }

  @include media-pc {
    margin-right: 16px;

    &__expand-icon {
      margin-top: 8px;

      &::before {
        content: "\e903";
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
    }

    &__item {
      &:first-child {
        margin-right: 12px;

        a {
          margin-top: 2px;
          padding: 0;

          &::after {
            bottom: -4px;
          }
        }

        .l-gnav__expand-icon {
          margin-top: 4px;
        }

        .l-gnav__link.-active .l-gnav__expand-icon {
          margin-top: 8px;
        }
      }
    }

    &__link {
      height: 56px;

      &::after {
        @include centering(true, false);
        position: absolute;
        bottom: -5px;
        width: 0;
        height: 3px;
        background: $cyan;
        backface-visibility: hidden;
        @include transition;
        content: "";
      }

      &:hover,
      &.-active,
      &.-current {
        color: $cyan;

        &::after {
          width: 100%;
        }

        .l-gnav__expand-icon {
          &::before {
            color: $cyan;
          }
        }
      }

      &.-active {
        .l-gnav__expand-icon {
          margin-top: 12px;
          transform: rotate(-180deg);
        }
      }
    }
  }

  @include media-pc-s {

    &__item {
      &:first-child {
        width: 140px;
      }
    }

    &__link {
      padding: 6px 9px;
      line-height: 1;
    }
  }

  @include media-tablet {
    &__item {
      &:first-child {
        width: 118px;
      }
    }

    &__link {
      padding: 6px 6px;
    }
  }

  @include media-sp {
    margin-top: 0;
    padding: 0 12px;
    background: inherit;

    &__list {
      flex-direction: column;
      border-top: 2px solid #b7b7b7;
    }

    &__item {
      &:first-child {
        width: 100%;
      }
    }

    &__link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 50px;
      padding: 18px 10px 18px 6px;
      border-bottom: 2px solid #b7b7b7;
      font-size: 1.6rem;
      font-weight: 700;

      span {
        display: block;
      }

      &.-active {
        .l-gnav__expand-icon {
          &::before {
            content: "\e90e";
          }
        }
      }

      // ドロップダウン以外のリンクアイコン
      &:not(.js-gnav-dropdown) {
        &::after {
          position: absolute;
          right: 8px;
          color: $gray;
          font-family: "nssol-icon";
          font-size: 1.4rem;
          content: "\e90b";
        }
      }
    }

    &__expand-icon {
      &::before {
        font-size: 1.6rem;
        content: "\e90f";
      }

      &.-active {
        &::before {
          content: "\e90e";
        }
      }
    }
  }
}

/* .l-gnav__item:nth-child(3) .l-gnav-menu {
  display: block;
} */

.l-header__nav {
  display: block;
}

/* l-gnav-menu
----------------------------------------------------------- */
.l-gnav-menu {
  display: none;
  position: absolute;
  top: 126px;
  left: 0;
  width: 100%;

  padding-bottom: 60px;
  text-align: left;

  &.-active {
    display: block;
  }

  @include media-sp {
    position: relative;
    top: inherit;
  }

  @include media-pc {
    overflow-y: auto;
    max-height: 90vh;
    background: $white url(/assets/img/common/bg_drop_nav.png) no-repeat center/cover;


    &__menus {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1em;

      &.-col-3 {
        > div {
          width: 33.3%;
        }
      }

      &.-col-4 {
        > div {
          width: 25%;
          &:nth-child(n + 5) {
            margin-top: 4em;
          }
        }
      }
    }

    &__inner {
      max-width: $base-content-width;
      margin: 0 auto;
      padding: 50px 16px;
    }

    &__title {
      margin-bottom: 1em;
      font-size: 3.2rem;
      font-weight: 700;

      .icon-triangle-right {
        overflow: hidden;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 22px;
        margin-left: 12px;

        &::before {
          position: absolute;
          top: 0;
          color: $gray-darken-1;
          font-size: 2rem;
          transform: translateX(0);
          @include transition;
        }
      }
    }

    &__close {
      float: right;
      margin-top: 3.75em;
    }

    &__search {
      max-width: 900px;
      margin: 0 auto;
    }

    &__top {
      margin-right: 60px;
    }
  }

  @include media-pc {

    &__title {
      &:hover {

        .icon-triangle-right {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }
  }

  @include media-tablet {

    &__title {
      .icon-triangle-right {
        &::before {
          top: 6px;
        }
      }
    }
  }

  @include media-sp {
    padding: 16px 8px 8px 16px;
    background: #f6f6f6;

    &__inner {
      padding: 0;
    }

    &__title {
      margin-bottom: .5em;
      font-size: 1.4rem;
      font-weight: 700;

      a {
        display: block;
        position: relative;
      }

      .icon-triangle-right {
        @include centering(false, true);
        right: 4px;
        font-size: 1.2rem;

        &::before {
          color: $gray-darken-1;
        }
      }
    }

    &__list {
      li {
        margin-top: 8px;
      }
    }

    &__menus {
      padding-left: 1em;
    }

    &__close {
      display: none;
    }
  }
}

/* l-gnav-menu-list
----------------------------------------------------------- */

.l-gnav-menu-list {
  padding: 0 1em;

  &__title {
    margin-bottom: 1em;
    padding-bottom: 14px;
    border-bottom: 2px solid #A0A0A0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__item {
    line-height: 1.25em;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  &__link {
    display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    padding-left: 10px;

    .icon-triangle-right {
      overflow: hidden;
      position: relative;
      top: 2px;
      display: inline-block;
      width: 16px;
      height: 14px;

      &::before {
        position: absolute;
        top: 0;
        color: $gray-darken-1;
        font-size: 1.3rem;
        transform: translateX(0);
        @include transition;
      }
    }
  }

  @include media-pc {

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__link {

      &:hover {

        .icon-triangle-right {

          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }
  }

  @include media-sp {
    padding: 0;

    > ul {
      margin-top: .5em;
      margin-bottom: 10px;
      padding-left: 1em;
    }

    &__title {
      margin-bottom: 0;
    padding-bottom: 0;
      font-size: 1.4rem;
      border-bottom: none;

      a,
      span {
        padding: .75em 0;
      }

      span {
        display: block;
      }
    }

    &__item {
      padding-left: 1px;
    }

    &__link {
      padding-left: 0;

      .icon-triangle-right {
        &::before {
          font-size: 1.2rem;
        }
      }
    }
  }
}
