.corporate-top-message {
  display: flex;
  align-items: center;
  min-height: 460px;
  padding: 80px 1em;
  background: url(/assets/img/corporate/bg_corporate_message.jpg) no-repeat;
  background-size: cover;

  @include media-tablet {
    background-position-x: -200px;
  }

  @include media-sp {
    min-height: 120px;
    height: 108px;
    padding: 0;
  }

  &__inner {
    max-width: $base-content-width;
    width: 100%;
    margin: 0 auto;
  }

  &__text {
    max-width: 420px;
    color: $white;
    font-size: 2.8rem;
    font-weight: 500;
    text-shadow: 0 0 4px rgba(#3B4B58, .8);
    line-height: 1.6;
  }

  &__btn {
    width: 380px;
  }
}