/* ============================================================ */
/* mediaquery */
/* ============================================================ */

@mixin media-ie11 {

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @content;
  }
}

@mixin media-pc {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-pc-s {
  @media (max-width: 1080px) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin media-sp {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-sp-xs {
  @media (max-width: 320px) {
    @content;
  }
}

/* ============================================================ */
/* utility */
/* ============================================================ */

/* circle
----------------------------------------------------------- */
@mixin circle($size) {
  width: $size + px;
  height: $size + px;
  border-radius: 50%;
}

/* transition
----------------------------------------------------------- */
@mixin transition {
  transition: 0.15s all;
}

/* arrow-slide
----------------------------------------------------------- */
@mixin arrow-slide {
  animation: arrow-slide .5s cubic-bezier(.5, .08, .05, 1) normal;
  animation-fill-mode: both;
}

/* arrow-slide-bottom
----------------------------------------------------------- */
@mixin arrow-slide-bottom {
  animation: arrow-slide-bottom .5s cubic-bezier(.5, .08, .05, 1) normal;
  //animation-fill-mode: both;
}

/* btn effect
----------------------------------------------------------- */

@mixin btn-cover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .3s ease-in-out;
  transform: translateX(-100%);
  transform-origin: left;
  content: '';
}

@mixin btn-animation {
  animation-name: hoverTranslate;
  animation-duration: .7s;
  animation-fill-mode: both;
}

/* centering
----------------------------------------------------------- */
@mixin centering($horizontal: true, $vertical: true) {
  // @include centering(true, true);
  position: absolute;

  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}

/* screenReader-only
----------------------------------------------------------- */
@mixin sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}