.sitemap-item {
  $this: &;

  &__title {
    margin-bottom: 1em;
    padding-bottom: 14px;
    border-bottom: 1px solid #707070;
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 1.3;

    + #{$this}__sub-title {
      margin-top: 0;
    }
  }

  &__sub-title {
    margin: 1.75em 0 .8em;
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__link {
    display: flex;

    > span {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      align-self: flex-end;
      width: 100%;
    }

    .icon-triangle-right {
      overflow: hidden;
      position: relative;
      bottom: 4px;
      display: inline-block;
      width: 18px;
      height: 20px;

      &::before {
        position: absolute;
        color: $gray-darken-1;
        font-size: 2rem;
        transform: translateX(0);
        @include transition;
      }
    }
  }

  @include media-pc {

    &__link {
      &:hover {

        .icon-triangle-right {

          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }
  }

  @include media-tablet {
    &__link {
      .icon-triangle-right {
        bottom: 0;
        width: 14px;
        height: 16px;
      }
    }
  }

  @include media-sp {

    &__title {
      padding-bottom: 10px;
      font-size: 1.6rem;
    }

    &__sub-title {
      font-size: 1.4rem;
    }

    &__link {
      > span {
        align-items: center;
      }

      .icon-triangle-right {
        bottom: inherit;

        &::before {
          font-size: 1.3rem;
        }
      }
    }




  }
}