/* top-content-wrap
----------------------------------------------------------- */

.top-content-wrap {
  background: url(/assets/img/common/base_pc.png) repeat-y top/cover;

  @include media-sp {
    background: url(/assets/img/common/base_sp.png) repeat-y top/cover;
  }
}

/* top-hero
----------------------------------------------------------- */

.top-hero {
  position: relative;
  width: 100%;

  &__list {
    display: none;
    animation-name: top-hero-fadein;
    animation-duration: 1.5s;

    &.slick-initialized {
      display: block;
    }
  }

  &__item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__inner {
    width: 1236px;
    max-width: 1236px;
    padding: 0 20px;
  }

  &__text-box {
    max-width: 640px;
    padding: 1.5em;
    background: rgba($white, .8);
  }

  &__main-title {
    margin-bottom: 32px;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__lead-text {
    margin-right: 16px;
    font-weight: 700;
  }

  &__btn {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 326px;
    margin-top: 12px;
    padding: 1em 2em 1em 1.45em;
    border-radius: 6px;
    border: 1px solid $gray-darken-2;
    background: $white;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include transition;

    &::after {
      @include btn-cover;
      background-color: rgba($black, 0.08);
    }

    .icon-triangle-right {
      @include centering(false, true);
      right: 12px;
      overflow: hidden;
      display: inline-block;
      width: 18px;
      height: 25px;

      &::before {
        position: absolute;
        color: $gray-darken-1;
        font-size: 1.8rem;
        @include transition;
        transform: translateX(0);
      }
    }

    .icon-open-new {
      @include centering(false, true);
      right: 14px;
      overflow: hidden;
      display: inline-block;
      width: 16px;
      height: 18px;

      &::before {
        position: absolute;
        color: $gray-darken-1;
        font-size: 1.6rem;
        @include transition;
        transform: translateX(0);
      }
    }

    &.-ghost {
      background: transparent;
    }
  }

  &__text-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
    }
  }

  &__nav  {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 100%;
    background: #fff;
  }

  .slick-dots {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 300px;
    background: $white;

    >li {
      width: 2px;
      height: 100%;
      margin: 8px 0;
      background: $gray-lighten-1;

      &.slick-active {
        width: 4px;
        background: $cyan;
      }

      >button {
        opacity: 0;
        width: 40px;
        margin-left: -18px;
      }
    }
  }

  .slick-counter {
    position: absolute;
    top: 328px;
    right: 28px;
    font-weight: 500;

    .current,
    .total {
      display: block;
      line-height: 1;
    }

    .total {
      display: block;
      margin: .375em 0 0 1em;
    }

    .separator {
      position: absolute;
      top: -0.125em;
      left: .75em;
      width: 1px;
      height: 45px;
      background: $gray-darken-2;
      transform: rotate(45deg);
    }
  }

  &__scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 2.4em;
    bottom: -7vw;
  }

  &__scroll-text {
    z-index: 5;
    background: $white;
    color: $gray-darken-2;
    font-size: 1.2rem;
    letter-spacing: .05em;
    writing-mode: vertical-lr;
  }

  &__scroll-line {
    display: block;
    width: 1px;
    height: 56px;
    background: $gray-lighten-2;
    animation-name: top-hero-scroll;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;

    &::after {
      position: absolute;
      bottom: -20px;
      left: -14px;
      width: 30px;
      height: 30px;
      background: url(/assets/img/top/img_scroll_drop.svg) no-repeat;
      background-size: contain;
      content: "";
    }
  }

  @include media-pc {
    min-height: 480px;
    height: calc(100vh - 271px);

    &__inner {
      &.-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &__text-box {
      margin-right: 1.5em;
    }

    &__item {
      min-height: 480px;
      height: calc(100vh - 271px);
    }

    &__btn {

      .icon-triangle-right {
        &::before {
          top: 3px;
        }
      }
      &:hover {
        background: $white;

        .icon-triangle-right {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }

        .icon-open-new {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }

        &::after {
          @include btn-animation;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    &__item {
      padding-right: 80px;
    }
  }

  @include media-pc-s {
    height: 54vw;

    &__item {
      height: 54vw;
    }

    .slick-counter {

      .total {
        margin-top: 1em;
      }
    }
  }

  @include media-tablet {

    &__scroll {
      right: 1.5em;
    }

    &__btn {
      min-width: 240px;

      .icon-triangle-right {
        &::before {
          top: 6px;
        }
      }
      .icon-open-new {
        overflow: unset;
        width: 16px;
        height: 10px;
      }
    }

    .slick-counter {
      .total {
        margin-top: 1.5em;
      }
    }
  }

  @include media-sp {
    height: 380px;

    .slick-dots {
      width: 28px;
      height: 200px;

      >li {
        margin: 6px 0;
      }
    }

    .slick-counter {
      top: 210px;
      right: 6px;
      font-size: 1.2rem;

      .separator {
        height: 24px;
        top: .2em;
      }

      .total {
        margin: .425em 0 0 .75em;
      }
    }

    &__item {
      padding: 16px 28px 16px 0;
      height: 380px;
    }

    &__text-area {
      flex-direction: column;
      align-items: flex-start;

      p {
        font-size: 1.2rem;
      }
    }

    &__text-box {
      margin-bottom: 1.5em;
    }

    &__lead-text {
      margin-right: 0;
    }

    &__scroll {
      top: 260px;
      right: 13px;
      height: 100px;
    }

    &__scroll-text {
      font-size: .8rem;
    }

    &__scroll-line {
      height: 24px;

      &::after {
        bottom: -14px;
        left: -8px;
        width: 18px;
        height: 18px;
      }
    }

    &__main-title {
      margin-bottom: 0.8em;
      font-size: 2.4rem;
    }

    &__btn {
      width: 100%;
      padding: 10px 38px 10px 16px;
      font-size: 1.6rem;
      .icon-triangle-right {
        &::before {
          font-size: 1.3rem;
        }
      }
      .icon-open-new {
        overflow: unset;
        width: 16px;
        height: 10px;
        &::before {
          font-size: 1.3rem;
        }
      }
    }

    &__nav {
      width: 28px;
    }
  }
}

/* top-hot-topics
----------------------------------------------------------- */

.top-hot-topics {
  display: flex;
  position: absolute;
  bottom: -30px;
  right: 80px;
  z-index: 5;
  max-width: 1028px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 176px;
    padding: 16px;
    background: $gray-darken-2;
    color: $white;
    font-size: 1.6rem;
  }

  &__body {
    display: flex;
    align-items: center;
    padding: 6px 28px;
    background: $white;
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;

    >li {
      margin-right: 1.5em;
    }
  }

  @include media-pc {
    height: 70px;
    padding-left: 16px;
  }

  @include media-sp {
    right: inherit;
    left: 12px;
    width: 100%;
    height: 36px;
    padding-right: 12px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 100%;
      background: linear-gradient(to left, $white 40%, rgba($white, 0) 100%);
      content: "";
    }

    &__title {
      min-width: 86px;
      padding: 8px;
      font-size: 1.1rem;
    }

    &__body {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 6px 12px;
      white-space: nowrap;
    }

    &__tag-list {
      flex-wrap: nowrap;

      >li {
        margin-right: 1em;
      }
    }
  }
}

/* top-news
----------------------------------------------------------- */

.important-notices + .top-main-news {
  margin-top: 0;
}

.top-main-news {
  max-width: 92vw;
  margin: 45px auto 0;
  padding: 0 16px;

  .top-news {
    border-bottom: 1px solid $gray-lighten-2;
  }

  @include media-sp {
    padding: 0;
  }
}

.top-news {
  display: flex;
  padding: 24px 18px;
  border-top: 1px solid $gray-lighten-2;

  &__header {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  &__date {
    margin-right: 40px;
  }

  &__text {
    display: block;
    color: $marine-blue;
    font-size: 1.6rem;
    line-height: 1.6;

    &[target="_blank"],
    &[href$=".pdf"] {
      &::after {
        display: inline-block;
        margin-left: .5em;
        color: #4A4A4A;
        font-family: 'nssol-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    &[target="_blank"] {
      &::after {
        vertical-align: baseline;
        content: "\e910";
      }
    }

    &[href$=".pdf"] {
      &::after {
        font-size: 1.9rem;
        content: "\e90d";
        vertical-align: middle;

        @include media-sp {
          font-size: 1.5rem;
        }
      }
    }
  }

  @include media-pc {

    &__header {
      width: 320px;
    }

    &__date {
      min-width: 120px;
    }

    &__body {
      width: calc(100% - 320px);
    }

    &__text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-pc-s {
    max-width: 90vw;
  }

  @include media-tablet {
    &__date {
      min-width: 100px;
    }
  }

  @include media-sp {
    flex-direction: column;
    padding: 12px 0;

    &__header {
      margin-bottom: 8px;
    }

    &__date {
      margin-right: 12px;
    }

    &__text {
      font-size: 1.2rem;
    }
  }
}

/* top-heading
----------------------------------------------------------- */

.top-heading-01 {
  position: relative;
  margin-bottom: 1.75em;
  font-size: 4rem;
  font-weight: 500;
  text-align: center;

  &::after {
    @include centering(true, false);
    bottom: -18px;
    border-top: 2px solid $cyan;
    width: 56px;
    content: "";
  }

  @include media-sp {
    font-size: 2.4rem;

    &::after {
      bottom: -8px;
      width: 40px;
    }
  }
}

/* top-section
----------------------------------------------------------- */

.top-section {
  margin: 120px 0;

  @include media-sp {
    margin: 60px 0;
  }
}

/* top-stories
----------------------------------------------------------- */

.top-stories {
  margin: 112px 0;

  &__heading {

    position: relative;
    max-width: $base-content-width;
    margin: 0 auto;
  }


  &__rss {
    position: absolute;
    top: 12px;
    right: 16px;
    font-size: 1.8rem;
    text-align: center;

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      @include transition;
      content: "\e906";
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    padding: 0 16px;
  }

  @include media-pc {
    &__rss {
      &:hover {
        color: $cyan;
      }
    }
  }

  @include media-sp {
    margin: 60px 0;

    &__rss {
      top: 40px;
      font-size: 1.4rem;

      &::after {
        font-size: 1.2rem;
      }
    }

    &__btn {
      margin-top: 28px;
    }
  }
}

.top-stories-list {
  display: flex;
  flex-wrap: wrap;
  max-width: $base-content-width;
  margin: 0 auto;

  .slick-list {
    overflow: inherit;
  }

  .slick-slide {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(#f9f9f9, .9);
      content: "";
      transition: all .3s;
    }
  }

  .slick-active {
    &::after {
      background: transparent;
      opacity: 0;
      height: 0;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 42%;
    z-index: 10;
    width: 24px;
    height: 44px;
    background-size: contain;
    text-indent: -9999px;
    content: "";

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .slick-prev {
    left: -4%;
    background: url(/assets/img/top/ico_slide_prev.svg) no-repeat top/contain;
  }

  .slick-next {
    right: -4%;
    background: url(/assets/img/top/ico_slide_next.svg) no-repeat top/contain;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    >li {
      position: relative;
      width: 80px;
        height: 2px;
      margin: 0 8px;
      background: $gray-lighten-1;

      >button {
        opacity: 0;
        position: absolute;
        top: -18px;
        width: 100%;
        text-indent: -9999px;
      }

      &.slick-active {
        height: 4px;
        margin-top: -1px;
        background: $cyan;
      }
    }
  }

  &__item {
    width: calc(100% / 3);
  }

  @media (max-width: 1280px) {

    width: 90%;
    margin: 0 auto;

    .slick-arrow {
      width: 18px;
      height: 32px;
    }
  }

  @include media-sp {
    width: 93%;
    margin-left: 0;

    &__item {
      width: 50%;
    }

    .slick-arrow {
      opacity: 0;
    }

    .slick-dots {
      margin-top: 12px;

      >li {
        width: 40px;

        button {
          top: -14px;
          height: 32px;
        }
      }
    }

    &.-change {
      margin-right: 0;
      margin-left: inherit;
    }
  }
}

/* top-ss
----------------------------------------------------------- */

.top-ss {
  padding: 120px 16px;
  background: url(/assets/img/top/bg_ss.jpg) no-repeat top/cover;
  background-attachment: fixed;

  @include media-ie11 {
    background-attachment: scroll;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  @include media-sp {
    padding: 32px 16px;
    background-size: initial;

    &__inner {
      flex-direction: column;
    }
  }
}

.top-ss-search {
  width: 73%;
  padding: 5%;
  background: $white;

  @include media-pc {
    &__tab-panel {
      min-height: 340px;
    }
  }

  @include media-sp {
    width: 100%;
  }
}

.top-ss-seminar {
  width: 24%;
  padding: 5% 2.5%;
  background: $white;

  &__title {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  &__info {
    margin-bottom: 20px;
  }

  &__info-title {
    font-weight: 500;
  }

  &__info-data {
    line-height: 1.6;
  }

  &__link-box {
    line-height: 1.6;

    a {
      display: block;
      margin-bottom: 6px;
      color: $marine-blue;
    }

    span {
      display: block;
      padding-left: 1.714em;
      font-size: 1.4rem;
    }
  }

  &__content-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message {
    color: $gray-darken-1;
    font-weight: 500;
    line-height: 1.5;
  }

  &__btn {
    margin: 32px 0 40px;
  }

  &__rss {
    display: block;
    font-size: 1.8rem;
    text-align: center;

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  @include media-pc {

    &__content-wrap {
      min-height: 15.625em;
    }

    &__rss {
      &:hover {
        color: $cyan;
      }
    }
  }

  @include media-sp {
    width: 100%;
    padding: 24px 16px;

    &__link-box {
      margin-bottom: 20px;

      span {
        padding-left: 21px;
        font-size: 1.2rem;
      }
    }

    &__btn {
      margin-bottom: 20px;

      .c-btn {
        justify-content: center;
      }
    }

    &__rss {
      font-size: 1.4rem;

      &::after {
        font-size: 1.2rem;
      }
    }
  }


}

/* top-csr
----------------------------------------------------------- */

.top-csr {
  padding: 120px 16px;
  background: url(/assets/img/top/bg_csr.jpg) no-repeat center/cover;
  background-attachment: fixed;

  @include media-ie11 {
    background-attachment: scroll;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__list {
    display: flex;
  }

  &__item {
    width: calc(100% / 3);
    height: 100%;
  }

  &__link {
    overflow: hidden;
    display: block;
    position: relative;
  }

  &__link-text {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 220px;
    padding: 10px 32px 10px 24px;
    background: rgba($white, .9);
    font-weight: 500;
    @include transition;

    .icon-triangle-right {
      display: inline-block;
      overflow: hidden;
      @include centering(false, true);
      right: 11px;

      &::before {
        color: $gray-darken-1;
      }
    }

    &::before {
      color: $gray-darken-1;
      transform: translateX(0);
    }
  }

  @include media-pc {
    &__link {
      img {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        img {
          transform: scale(1.09);
        }

        .top-csr__link-text {
          background: $white;

          .icon-triangle-right {
            &::before {
              color: $yellow;
              @include arrow-slide;
            }
          }
        }
      }
    }
  }

  @include media-sp {
    padding: 32px 16px;

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &__link-text {
      min-width: 150px;
      padding: 6px 24px;
      font-size: 1.2rem;

      &::after {
        right: 10px;
      }

      .icon-triangle-right {
        right: 8px;
      }
    }
  }
}


/* top-ir
----------------------------------------------------------- */

.top-ir {
  max-width: $base-content-width;
  margin: 0 auto;
  padding: 0 16px;

  &__list {
    display: flex;
  }

  &__item {
    width: calc(100% / 3);
    height: 100%;
  }

  &__link {
    display: block;
  }

  &__image {
    overflow: hidden;
  }


  &__link-text {
    display: block;
    position: relative;
    margin-top: 8px;
    font-weight: 500;
    text-align: center;
    @include transition;

    .icon-triangle-right {
      margin-left: 4px;

      &::before {
        color: $gray-darken-1;
        @include transition;
      }
    }
  }

  @include media-pc {
    &__link {
      img {
        transition: all 0.2s ease-in-out;
      }

      .icon-triangle-right {
        display: inline-block;
        overflow: hidden;

        &::before {
          transform: translateX(0);
        }
      }

      &:hover {
        img {
          transform: scale(1.09);
        }

        .icon-triangle-right {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }

    &__link-text {
      font-size: 1.7rem;

      .icon-triangle-right {
        font-size: 1.5rem;
      }
    }
  }

  @include media-sp {
    padding: 0 16px;

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &__link-text {
      min-width: 150px;
      font-size: 1.4rem;

      &::after {
        right: 10px;
      }
    }
  }
}



/* top-future
----------------------------------------------------------- */

.top-future {
  padding: 60px 16px;
  background: url(/assets/img/top/bg_future.jpg) no-repeat center/cover;
  background-attachment: fixed;

  @include media-ie11 {
    background-attachment: scroll;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__heading {
    position: relative;
    color: $white;
  }

  &__sub-title {
    display: block;
    margin-bottom: .75em;
    color: $white;
    font-size: 2.2rem;
    font-weight: 700;

    .icon-triangle-right {
      margin-left: 8px;

      &::before {
        color: $white;
        font-size: 1.8rem;
        @include transition;
      }
    }
  }

  &__title {
    margin-bottom: .5em;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1.3;
  }

  &__text {
    color: $white;
    font-size: 2rem;
    line-height: 1.6;
  }

  &__list {
    display: flex;
    justify-content: space-between;

  }

  &__item {
    width: 24%;
  }

  &__link {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 120px;
    border-radius: 5px;
    border: 3px solid $white;
    color: $white;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.4;

    &:focus {
      box-shadow: 0 0 0 3px rgba($white, 0.4);
      outline: none;
    }

    &::before {
      @include btn-cover;
      background-color: rgba($white, 0.3);
    }

    &::after {
      position: absolute;
      left: calc(50% - 23px);
      bottom: 20px;
      border-top: 3px solid $yellow;
      width: 46px;
      content: "";
      @include transition;
    }
  }

  &__footer {
    margin-top: 48px;
    padding-top: 24px;
    border-top: 1px solid $white;
    text-align: right;

    a {
      display: inline-flex;
      align-items: center;
      color: $white;
      font-size: 2rem;

      .icon-triangle-right {
        margin-left: 8px;

        &::before {
          color: $white;
          font-size: 1.8rem;
          @include transition;
        }
      }
    }
  }

  @include media-pc {
    &__link {
      &:hover {


        &::after {
          animation: top-btn-line 0.4s;
          animation-fill-mode: both;
        }
        &::before {
          z-index: 1;
          @include btn-animation;
        }
      }
    }

    &__sub-title {
      display: inline-block;

      .icon-triangle-right {
        overflow: hidden;
        display: inline-block;

        &::before {
          transform: translateX(0);
        }
      }


      &:hover {
        color: $yellow;

        .icon-triangle-right {

          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }


    &__footer {
      a {
        .icon-triangle-right {
          overflow: hidden;
          display: inline-block;

          &::before {
            transform: translateX(0);
          }
        }

        &:hover {
          color: $yellow;

          .icon-triangle-right {
            &::before {
              color: $yellow;
              @include arrow-slide;
            }
          }
        }
      }
    }
  }

  @include media-sp {

    &__sub-title {
      font-size: 1.6rem;

      .icon-triangle-right {
        &::before {
          font-size: 1.4rem;
        }
      }
    }

    &__title {
      font-size: 3.6rem;
    }

    &__text {
      font-size: 1.4rem;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;

      &:not(:first-child) {
        margin-top: 18px;
      }
    }

    &__link {
      height: 100px;
      font-size: 1.6rem;

      &::after {
        bottom: 12px;
      }
    }

    &__footer {
      a {
        font-size: 1.6rem;

        .icon-triangle-right::before {
          font-size: 1.4rem;
        }
      }
    }
  }
}


/* top-press
----------------------------------------------------------- */

.top-press {
  margin: 120px 0 40px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    padding: 0 16px;
  }

  &__header {
    position: relative;
  }

  &__rss {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: 1.8rem;
    text-align: center;

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  &__list {
    border-bottom: 1px solid $gray-lighten-2;
  }

  @include media-pc {
    &__rss {
      &:hover {
        color: $cyan;
      }
    }
  }

  @include media-sp {
    margin: 60px 0 0;

    &__rss {
      top: 40px;
      font-size: 1.4rem;

      &::after {
        font-size: 1.2rem;
      }
    }
  }
}

[lang="en"] {
  .top-press {
    margin-bottom: 0;
    padding-bottom: 120px;

    @include media-sp {
      padding-bottom: 80px;
    }
  }
}

/* top-recruit
----------------------------------------------------------- */

.top-recruit {
  padding: 80px 16px 120px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__list {
    display: flex;
  }

  &__item {
    width: 50%;
  }

  &__link {
    display: block;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      transition: all 0.2s ease-in-out;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, .44);
      @include transition;
      content: "";
    }
  }

  &__link-text {
    display: flex;
    align-items: center;
    @include centering(true, true);
    z-index: 5;
    color: $white;
    font-size: 2.2rem;
    font-weight: 700;

    &::after {
      padding-left: 6px;
      color: $white;
      font-family: 'nssol-icon';
      font-size: 1.6rem;
      font-weight: normal;
      vertical-align: text-top;
      content: "\e910";
    }
  }

  @include media-pc {

    &__link {
      &:hover {
        img {
          transform: scale(1.09);
        }

        &::after {
          background: rgba($black, .56);
        }

        .top-recruit__link-text {

          &::after {
            color: $yellow;
          }
        }
      }
    }
  }

  @include media-sp {
    padding-top: 60px;

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      height: 100%;
    }

    &__link-text {
      font-size: 1.6rem;

      &::after {
        font-size: 1.4rem;
      }
    }
  }
}

/* top-sns
----------------------------------------------------------- */
.top-sns {
  padding-bottom: 80px;

  &__inner {
    max-width: 1236px;
    margin: 0 auto;
    .c-grid {
      margin: -1em auto;
      max-width: 60%;
      a:hover {
        opacity: 0.5;
      }
    }
  }
  @include media-sp {
    .c-grid {
      max-width: 100%;
    }
  }
}
.sns-icon {
  width: 65px;
}
@include media-sp {
  .sns-icon {
    width: 50px;
  }
}

/*追記20240312*/
 /*トップニュース*/
 .top-main-news {
  width: 90vw;
  margin: 60px auto 0;
  border-bottom: 1px solid #D4D4D4;
  padding-bottom: 24px;
}
.top-main-news .top-news {
  padding: 18px 10px 0;
  border: none;
}
@media (max-width: 1080px) {
  .top-main-news .top-news {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .top-main-news {
    margin: 40px 16px 0;
  }
  .top-main-news .top-news {
    padding: 12px 0;
  }
  .top-main-news .top-news:last-of-type {
    padding-bottom: 0;
  }
}
/*重要なお知らせ*/
.top-important {
  margin: 120px 0 40px;
}
.top-important__inner {
  max-width: 1236px;
  margin: 0 auto;
  padding: 0 16px;
}
.important-notices {
  display: block;
  max-width: 100%;
  margin: 0;
  background: none;
  border-top: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;
  padding: 16px;
}
.important-notices__body {
  display: flex;
  width: 100%;
  max-width: 960px;
  margin: auto;
  margin-bottom: 1em;
}
.important-notices__body:last-of-type {
  margin-bottom: 0;
}
.important-notices__link {
  padding: 0;
}
@media (max-width: 767px) {
  .top-important {
    margin: 60px 0 0;
  }
  .important-notices {
    width: 100%;
    padding: 16px 0;
  }
}
