[class^="disaster-notices-"] {
  width: calc(100% - 32px);
  max-width: $base-content-width;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  padding-right: 2%;
  padding-left: 2%;
  border-right: 1px solid #F22929;
  border-left: 1px solid #F22929;
  background: $white;

  @include media-sp {
    padding-right: 14px;
  padding-left: 14px;
  }
}

.disaster-notices-title {
  padding: 8px;
  border-top: 1px solid #F22929;
  background: #fedbd8;
  font-size: 1.9rem;
  font-weight: 700;
  text-align: center;

  + .disaster-notices-sub-title {
    padding-top: 1.5em;
  }

  @include media-sp {
    font-size: 1.6rem;
  }
}

.disaster-notices-sub-title {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.4;

  @include media-sp {
    font-size: 1.6rem;
  }
}

.disaster-notices-sub-title-02 {
  position: relative;
  padding-top: 1.25em;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.4;

  &::before {
    position: absolute;
    top: 0;
    left: 2%;
    width: 96%;
    height: 1px;
    background: #F22929;
    content: "";
  }

  @include media-sp {
    font-size: 1.6rem;

    &::before {
      left: 14px;
      width: calc(100% - 28px);
    }
  }
}

.disaster-notices-text {
  padding-top: .5em;
  padding-bottom: 1.25em;

  &:first-of-type {
    padding-top: 1em;
  }
  &:last-of-type {
    padding-bottom: 1em;
  }
}

.disaster-notices-links {
  padding-top: 1em;
  margin-bottom: 32px;
  padding-bottom: 2%;
  border-bottom: 1px solid #F22929;

  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    + a {
      margin-top: 1em;
    }
  }

  @include media-sp {
    margin-bottom: 24px;
  }
}

.important-notices {
  $this: &;
  display: flex;
  align-items: center;
  max-width: calc(92vw - 32px);
  margin: 2.5em auto 0;
  padding-left: 5em;
  background: $white;

  &__header {
    width: 25%;
    padding-right: 5%;
    text-align: center;
  }

  &__title {
    display: inline-flex;
    position: relative;
    padding-left: 26px;
    font-size: 1.9rem;
    font-weight: 700;
    text-align: center;

    &::before {
      @include centering(false, true);
      left: 0;
      background: url(/assets/img/common/ico_important_red.svg) no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      content: "";
    }
  }

  &__body {
    width: 75%;
  }

  &__link {
    display: block;
    padding: 1.5em 0;
    color: $marine-blue;

    &[target="_blank"],
    &[href$=".pdf"] {
      &::after {
        display: inline-block;
        margin-left: .5em;
        color: #4A4A4A;
        font-family: 'nssol-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    &[target="_blank"] {
      &::after {
        vertical-align: baseline;
        content: "\e910";
      }
    }

    &[href$=".pdf"] {
      &::after {
        font-size: 1.9rem;
        content: "\e90d";
        vertical-align: middle;

        @include media-sp {
          font-size: 1.5rem;
        }
      }
    }

    &:hover {
      text-decoration: underline;
    }

    + #{$this}__link {
      border-top: 1px solid #D4D4D4;
    }
  }

  span.important-notices__link {
    color: $base-font-color;
    &:hover {
      text-decoration: none;
    }
  }

  @include media-tablet {
    padding: 0 2.5em;
  }

  @include media-sp {
    flex-direction: column;
    max-width: inherit;
    width: calc(100% - 32px);
    margin-top: 40px;
    padding: 1.5em 1em 0;

    &__header {
      width: 100%;
    }

    &__title {
      padding-left: 20px;
      font-size: 1.6rem;

      &::before {
        width: 16px;
        height: 16px;
      }
    }

    &__body {
      width: 100%;
    }
  }
}