.c-tab {
  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-bottom: 18px;
    border-bottom: 4px solid $gray-lighten-1;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.4;
    cursor: pointer;
    @include transition;

    &:hover {
      border-bottom: 4px solid rgba($cyan, .4);
    }

    &.-active {
      border-color: $cyan;
    }
  }

  &__panel {
    display: none;

    &:first-child {
      display: block;
    }
  }

  @include media-pc {}

  @include media-sp {

    &__list {
      &.-col-5 {
        flex-wrap: wrap;

        .c-tab__btn {
          width: 33.3%;

          &:nth-child(4),
          &:nth-child(5) {
            width: 50%;
          }
        }
      }
    }

    &__btn {
      padding: 1.2em 0;
      font-size: 1.3rem;
      letter-spacing: -0.01em;
    }

    &__panel {
      min-height: inherit;
    }
  }
}