* {

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-size: 62.5%;

  @media screen and (min-width: 768px) and (max-width:1120px) {
    font-size: calc(100vw / 112);
  }
}

body {
  color: $base-font-color;
  font-family: $base-font-family;
  line-height: 1.8;
  font-size: 1.6rem;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;

  @media only screen and(-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.is-hidden {
    overflow: hidden;
  }
}

a {
  color: $base-font-color;
  text-decoration: none;
}

p {
  margin: 0 0 1.2em;
  line-height: 1.8;

  &:last-child {
    margin-bottom: 0;
  }

  > a {
    color: $marine-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dl dd {
  margin: 0;
}

table {
  border-collapse: collapse;
}

figure {
  margin: 0;
}

iframe {
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}

fieldset {
  appearance: none;
  margin: 0;
  border: none;
}

button {
  appearance: none;
  margin: 0;
  border: none;
  background: inherit;
  cursor: pointer;
}

code {
  font-family: Monaco, Consolas, 'Courier New', Courier, monospace, sans-serif;
}
/* PC
----------------------------------------------------------- */

@include media-pc {

  a {
    cursor: pointer;
    @include transition;
  }

  .sp-view {
    display: none !important;
  }
}

/* SP
----------------------------------------------------------- */

@include media-sp {

  body {
    font-size: 1.4rem;
  }

  .pc-view {
    display: none !important;
  }
}