.seminar-heading {
  position: relative;

  &__rss {
    position: absolute;
    top: 8px;
    right: 0;
    font-size: 1.8rem;
    text-align: center;

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  @include media-sp {
    &__rss {
      font-size: 1.4rem;
      top: 0;

      &::after {
        font-size: 1.2rem;
      }
    }
  }
}

.seminar-list {

  &__item {
    border-bottom: 1px solid $gray-lighten-2;
  }

  @include media-pc {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 49%;

      &:nth-child(even) {
        margin-left: 2%;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-top: 1px solid $gray-lighten-2;
      }
    }
  }

  @include media-sp {
    border-top: 1px solid $gray-lighten-2;
  }
}

.seminar-item {
  padding: 28px 0;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__status {
    margin-left: 16px;
    font-weight: 700;

    &.-accept {
      color: #13B545;
    }

    &.-full {
      color: #E0003C;
    }

    &.-closed {
      color: #707070;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__sub-title {
    padding-left: 1.714em;
    font-size: 1.4rem;
  }

  &__info {
    p {
      display: flex;
      margin-bottom: 0;
      padding-left: 1.714em;
      font-size: 1.4rem;

      span {
        min-width: 74px;
      }

      span:first-child {
        white-space: nowrap;
        padding-right: 8px;
      }
    }
  }

  @include media-sp {
    padding: 24px 0 16px;

    &__title {
      margin-bottom: 16px;
    }

    &__sub-title {
      padding-left: 1.615em;
      font-size: 1.3rem;
    }

    &__info {
      p {
        padding-left: 1.615em;
        font-size: 1.3rem;
      }
    }
  }
}

.seminar-airticle {
  &__heading {
    margin-bottom: 56px;
  }

  &__heading-main {
    margin-bottom: 0;
  }

  &__heading-sub {
    font-size: 2.3rem;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  &__status {
    margin-left: 16px;
    font-weight: 700;

    &.-accept {
      color: #13B545;
    }

    &.-full {
      color: #E0003C;
    }

    &.-closed {
      color: #707070;
    }
  }

  &__body {
    margin-bottom: 80px;
  }

  &__btn {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @include media-sp {
    &__heading {
      margin-bottom: 30px;
    }

    &__heading-sub {
      font-size: 1.4rem;
    }

    &__header {
      margin-bottom: 24px;
    }

    &__status {
      margin-left: 8px;
    }

    &__body {
      margin-bottom: 40px;
    }

  }
}

.seminar-airticle__body {
  table td,
  p {
    >a {
      color: $marine-blue;

      &[target="_blank"],
      &[href$=".pdf"],
      &[href^="mailto:"] {
        padding-right: .5em;

        &::after {
          display: inline-block;
          margin-left: .5em;
          color: #4A4A4A;
          font-family: 'nssol-icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: middle;
        }
      }

      &[target="_blank"] {
        &::after {
          vertical-align: baseline;
          content: "\e910";
        }
      }

      &[href$=".pdf"] {

        &::after {
          font-size: 1.9rem;
          content: "\e90d";
          vertical-align: middle;

          @include media-sp {
            font-size: 1.5rem;
          }
        }
      }

      &[href^="mailto:"] {
        &::after {
          font-size: 1.9rem;
          content: "\e911";

          @include media-sp {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}