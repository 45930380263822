.stories-article__body,
.press-article__body,
.post__body {
  max-width: $base-content-width;
    margin: 0 auto;

    /* リンク
    ------------------ */
    table td,
    p {
      >a {
        color: $marine-blue;

        &[target="_blank"],
        &[href$=".pdf"],
        &[href^="mailto:"] {
          padding-right: .5em;

          &::after {
            display: inline-block;
            margin-left: .5em;
            color: #4A4A4A;
            font-family: 'nssol-icon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: middle;
          }
        }

        &[target="_blank"] {
          &::after {
            vertical-align: baseline;
            content: "\e910";
          }
        }

        &[href$=".pdf"] {

          &::after {
            font-size: 1.9rem;
            content: "\e90d";
            vertical-align: middle;

            @include media-sp {
              font-size: 1.5rem;
            }
          }
        }

        &[href^="mailto:"] {
          &::after {
            font-size: 1.9rem;
            content: "\e911";

            @include media-sp {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    /* テキスト
    ------------------ */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      margin: 1.75em 0 1em;
      font-weight: 500;
      line-height: 1.4;
    }

    .c-box {
      h1,h2,h3,h4,h5,h6 {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 4.4rem;
    }

    h2 {
      margin-bottom: 1.5em;
      font-size: 3.6rem;
      text-align: center;

      &:first-child {
        margin-top: 0;
      }

      &::after {
        @include centering(true, false);
        bottom: -24px;
        border-top: 2px solid $cyan;
        width: 56px;
        content: "";
      }
    }

    h3 {
      font-size: 2.8rem;
    }

    h4 {
      font-size: 2.2rem;
    }

    h5 {
      font-size: 1.8rem;
    }

    p {
      margin: 1.5em 0;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .c-grid,
    .c-box {
      ul {
        margin: 0;
      }
      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    /* リスト
    ------------------ */

    ul {
      margin: 1.75em 0;

      li {
        position: relative;

        >ul {
          margin-top: 1em;
        }
      }

      &:not([class]) {
        li {
          padding-left: 1.5em;

          &:not(:first-child) {
            margin-top: .75em;
          }

          &::before {
            position: absolute;
            top: .85em;
            left: .5em;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $base-font-color;
            content: "";

            @include media-sp {
              top: .8em;
            }
          }
        }
      }
    }

    ol {
      margin: 1.75em 0;
      padding-left: 1.5em;
      list-style-type: decimal;

      >li {
        padding-left: .5em;
      }
    }

    .c-list {
      &.-note {
        li {
          &::before {
            top: 0;
            left: 0;
            width: 0;
            height: 0;
          }
        }
      }
    }

    .btn-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1.75em 0;

      .c-btn {
        margin: 0 1em 1em 0;
      }
    }

    /* テーブル
    ------------------ */
    table {
      width: 100%;
      margin: 1.75em 0;

      caption {
        margin-bottom: 1em;
      }

      tr {
        border-top: 1px solid $base-border-color;

        &:last-child {
          border-bottom: 1px solid $base-border-color;
        }
      }

      th,
      td {
        padding: 1.5em;
        border: 1px solid #a0a0a0;
      }

      th {
        background: #E8E8E8;
        font-weight: 500;
      }

      td {
        background: $white;
      }

      @include media-sp {

        th,
        td {
          padding: 14px 12px;
          font-size: 1.4rem;

        }

        th {
          line-height: 1.4;
        }

        td {
          line-height: 1.6;
        }

      }
    }

    /* インタビュー
    ------------------ */
    .interview {
      font-weight: 500;

      + .answer {
        margin-top: 8px;
      }
    }

    .answer {
      .name {
        font-weight: 500;
      }
    }
    /* 要素マージン追加
    ------------------ */
    .c-image,
    .c-movie {
      margin-bottom: 32px;
    }

}

@include media-sp {
  .stories-article__body,
  .press-article__body,
  .post__body {
    h1 {
      font-size: 2.8rem;
    }

    h2 {
      font-size: 2.4rem;

      &::after {
        width: 40px;
        bottom: -16px;
      }
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.6rem;
    }

    h5 {
      font-size: 1.4rem;
    }
  }
}

.press-article__body,
.press-article__footer {
  a {
    color: $marine-blue;

    &.c-btn {
      color: $base-font-color;
    }

    @include media-pc {

      &:hover {
        text-decoration: underline;
      }

      &.c-btn:hover {
        text-decoration: none;
      }
    }

    &[target="_blank"],
    &[href$=".pdf"],
    &[href^="mailto:"] {
      padding-right: .5em;

      &::after {
        display: inline-block;
        margin-left: .5em;
        color: #4A4A4A;
        font-family: 'nssol-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    &[target="_blank"] {
      &::after {
        vertical-align: baseline;
        content: "\e910";
      }
    }

    &[href$=".pdf"] {

      &::after {
        font-size: 1.9rem;
        content: "\e90d";
        vertical-align: middle;

        @include media-sp {
          font-size: 1.5rem;
        }
      }
    }

    &[href^="mailto:"] {
      &::after {
        font-size: 1.9rem;
        content: "\e911";

        @include media-sp {
          font-size: 1.5rem;
        }
      }
    }
  }

  p {
    > a {
      @include media-pc {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.press-article__footer {
  a {
    + p {
      margin-top: 1.2em;
    }
  }
}
