/* c-form
----------------------------------------------------------- */

.c-form {

  &__required-label {
    color: $red;
  }

  .error {
    background: $bg-danger;

    p.error {
      display: inline-block;
      margin-bottom: 15px;
      padding: 4px 10px;
      background: #FF4C4C;
      color: $white;
      font-weight: 500;
      line-height: 1.4;
    }
  }

  &__example {
    margin: 4px 0 0;
    font-size: 1.4rem;
  }

  &__note {
    font-size: 1.2rem;
    text-align: right;
  }

  @include media-sp {
    input,
    select,
    textarea {
      font-size: 1.6rem;
    }

    &__title {
      min-width: 100px;
    }

    &__example {
      font-size: 1.3rem;
    }

    &__note {
      margin-top: 4px;
      font-size: 1.1rem;
      text-align: left;
    }

  }
}

/* c-input
----------------------------------------------------------- */

.c-input {
  label {
    cursor: pointer;
  }

  p + input {
    margin-top: 18px;
  }

  &__name-block {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    width: 49%;
  }

  &__number-block {
    display: flex;
  }

  &__number {
    position: relative;
    width: 25%;
    max-width: 200px;

    &:not(:first-child) {
      margin-left: 3%;

      &::before {
        position: absolute;
        top: 32%;
        left: -8.5%;
        width: 10px;
        height: 1px;
        background: #111;
        content: "";

        @include media-tablet {
          width: 8px;
        }
      }
    }
  }

  &__text {
    width: 100%;
    padding: 17px;
    border-radius: 6px;
    border: 1px solid #A0A0A0;


    &::placeholder {
      color: $txt-gray;
    }

    &.-small {
      width: 50%;
    }
  }

  &__email-block {
    display: flex;
    align-items: center;
    span {
      margin: 0 8px;
    }
  }

  &__textarea {
    resize: vertical;
    width: 100%;
    padding: 17px;
    border-radius: 6px;
    border: 1px solid #A0A0A0;

    &::placeholder {
      color: $txt-gray;
    }
  }

  &__btn-list {

    >li {
      display: inline-block;
      margin-right: 32px;
      line-height: 2.4;
    }
  }

  &__radio {
    cursor: pointer;
  }

  &__checkbox {
    display: inline-block;
    margin-bottom: 16px;

    span {
      position: relative;
      padding-left: 40px;
      font-size: 1.8rem;

      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        width: 26px;
        height: 26px;
        border: 1px solid #A0A0A0;
        border-radius: 4px;
        background: $white;
        content: "";

        @include media-tablet {
          top: -3px;
        }
      }
    }

    input[type="checkbox"] {
      @include sr-only;

      &:focus {
        + span {

          &::before {
            box-shadow: 0 0 0 3px rgba($cyan, 0.3);
          }
        }
      }

      &:checked + span {

        &::after {
          position: absolute;
          top: 2px;
          left: 6px;
          width: 24px;
          height: 14px;
          border-bottom: 4px solid $cyan;
          border-left: 4px solid $cyan;
          transform: rotate(-45deg);
          content: "";
        }
      }
    }
  }

  &__select {
    position: relative;
    width: 400px;

    + input {
      margin-top: 18px;
    }

    select {
      width: 100%;
      padding: 17px 32px 17px 17px;
      border-radius: 6px;
      border: 1px solid #A0A0A0;
      background: $white;
      cursor: pointer;
      appearance: none;
    }

    &::before {
      @include centering(false, ture);
      right: 32px;
      width: 30px;
      height: 28px;
      background: $white;
      content: "";
    }

    &::after {
      @include centering(false, ture);
      right: 10px;
      color: $gray-darken-1;
      font-family: 'nssol-icon';
      content: "\e909";
    }
  }

  @include media-sp {

    &__name-block,
    &__number-block {
      flex-direction: column;
    }

    &__email-block {
      flex-wrap: wrap;
      .c-input__text {
        width: 85%;
      }

      span + .c-input__text {
        margin-top: 12px;
      }
    }

    &__name {
      width: 100%;

      + .c-input__name {
        margin-top: 18px;
      }
    }

    &__number {
      width: 90%;
      margin-left: 5%;

      &:not(:first-child) {
        margin-top: 12px;
        margin-left: 5%;
      }
    }

    &__text {
      width: 100%;
      padding: 13px;
    }

    &__checkbox {
      span {
        font-size: 1.6rem;

        &::before {
          top: 0;
        }
      }
    }

    &__select {
      width: 100%;

      &::before {
        content: none;
      }

      select {
        width: 100%;
        padding: 14px 30px 14px 14px;
      }
    }
  }
}