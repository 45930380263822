@font-face {
  font-family: 'nssol-icon';
  src:
    url('/assets/icon/fonts/nssol-icon.ttf?x049r6') format('truetype'),
    url('/assets/icon/fonts/nssol-icon.woff?x049r6') format('woff'),
    url('/assets/icon/fonts/nssol-icon.svg?x049r6#nssol-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nssol-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    display: inline-block;
  }
}

.icon-zip:before {
  content: "\e912";
}
.icon-mail:before {
  content: "\e911";
}
.icon-pdf:before {
  content: "\e90d";
}
.icon-open-new:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-global:before {
  content: "\e905";
}
.icon-rss:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-remove:before {
  content: "\e90e";
}
.icon-add:before {
  content: "\e90f";
}
.icon-angle-top:before {
  content: "\e900";
}
.icon-angle-right:before {
  content: "\e901";
}
.icon-angle-left:before {
  content: "\e902";
}
.icon-angle-bottom:before {
  content: "\e903";
}
.icon-triangle-bottom:before {
  content: "\e909";
}
.icon-triangle-left:before {
  content: "\e90a";
}
.icon-triangle-right:before {
  content: "\e90b";
}

.c-pinchout-icon {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 25px;
  margin-right: 8px;
  vertical-align: middle;


  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background: url(/assets/img/common/ico_pinch-out.png) no-repeat;
    background-size: contain;
    width: 24px;
    height: 25px;
    content: "";
  }
}

/* c-icon
----------------------------------------------------------- */

.c-icon {
  color: #4A4A4A;

  &.-gray {
    color: $gray-darken-1;
  }

  &.-white {
    color: $white;
  }

  &.-left {
    margin-right: .5em;
  }

  &.-right {
    margin-left: .5em;
  }

  &.-xlarge {
    font-size: 2.2rem;
  }

  &.-large {
    font-size: 1.9rem;
  }

  &.-medium {
    font-size: 1.6rem;
  }

  &.-small {
    font-size: 1.4rem;
  }

  &.-xsmall {
    font-size: 1.2rem;
  }

  &.-xxsmall {
    font-size: 1rem;
  }

  @include media-sp {
    font-size: 1.3rem;

    &.-xlarge {
      font-size: 1.9rem;
    }

    &.-large {
      font-size: 1.6rem;
    }

    &.-medium {
      font-size: 1.4rem;
    }

    &.-small {
      font-size: 1.2rem;
    }

    &.-xsmall {
      font-size: 1.1rem;
    }

    &.-xxsmall {
      font-size: 1rem;
      &::before {
        transform: scale(.9);
      }
    }
  }

}