.c-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;

  > li {
    padding: 6px;
  }

  @include media-pc {
    &.-col-4 {
      > li {
        width: 25%;
      }
    }
  }

  @include media-sp {
    > li {
      width: 50%;
    }
  }
}

.c-checkbox {
  height: 50px;

  &.-max {
    span {
      width: 100%;
    }
  }

  input[type="checkbox"] {
    @include sr-only;

    &:focus {
      + span {
        box-shadow: 0 0 0 3px rgba($gray, .3);
      }
    }

    &:checked + span {
      background: #FFF7B5;

      &::after {
        position: absolute;
        top: 14px;
        left: 18px;
        width: 18px;
        height: 10px;
        border-bottom: 3px solid $cyan;
        border-left: 3px solid $cyan;
        transform: rotate(-45deg);
        content: "";
      }
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 196px;
    height: 50px;
    padding: 0 25px 0 36px;
    border: 1px solid #A0A0A0;
    border-radius: 100px;
    background: $white;
    font-size: 1.4rem;
    line-height: 1.3;
    letter-spacing: -.05em;
    cursor: pointer;
    @include transition;

    &::before {
      @include centering(false, true);
      left: 14px;
      width: 18px;
      height: 18px;
      border: 1px solid #A0A0A0;
      border-radius: 4px;
      background: $white;
      content: "";
    }
  }

  @include media-pc {
    span {
      text-align: center;
    }

    &:hover {
      span {
        background: #FFF7B5;
      }
    }
  }

  @include media-sp {
    width: 100%;
    height: 44px;

    input[type="checkbox"] {
      &:checked + span {
        &::after {
          top: 16px;
          left: 14px;
          width: 14px;
          height: 8px;
        }
      }
    }

    span {
      width: auto;
      padding: 0 10px 0 30px;
      font-size: 1.2rem;

      &::before {
        left: 12px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
      }
    }
  }
}