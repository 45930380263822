.c-heading {
  position: relative;
  margin-bottom: 1em;
  font-weight: 500;
  line-height: 1.4;

  &.-xlarge {
    font-size: 4.4rem;
    font-weight: 700;
  }

  &.-large {
    font-size: 3.6rem;
  }

  &.-medium {
    font-size: 2.8rem;
  }

  &.-small {
    font-size: 2.2rem;
  }

  &.-xsmall {
    font-size: 2rem;
  }

  &.-xxsmall {
    font-size: 1.8rem;
  }

  &.-border {
    margin-bottom: 2em;

    &::after {
      @include centering(true, false);
      bottom: -24px;
      border-top: 2px solid $cyan;
      width: 56px;
      content: "";
    }
  }

  &.-number {
    text-indent: -1em;
    padding-left: 1em;
  }

  &.-blue {
    color: #0083E3;
  }

  &.-center {
    text-align: center;
  }

  @include media-sp {
    &.-xlarge {
      font-size: 2.8rem;
    }

    &.-large {
      font-size: 2.4rem;
    }

    &.-medium {
      font-size: 2rem;
    }

    &.-small {
      font-size: 1.6rem;
    }

    &.-xsmall {
      font-size: 1.4rem;
    }

    &.-xxsmall {
      font-size: 1.4rem;
    }

    &.-border {
      &::after {
        width: 40px;
        bottom: -16px;
      }
    }
  }
}