.c-movie {
  max-width: 640px;
  margin: 0 auto;

  &__item {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      //padding-top: 56.25%;
    }
  }
}

