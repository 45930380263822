/* l-footer
----------------------------------------------------------- */
.l-footer {
  position: relative;

  &__pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 15;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #a0a0a0;
    background:$white;
    transition: none;

    &::after {
      @include centering(true, true);
      color: $gray-darken-1;
      font-family: 'nssol-icon';
      content: "\e90c";
    }

    span {
      @include sr-only;
    }
  }

  &__top {
    padding: 100px 16px;
    background: $gray-lightest;
  }

  &__top-inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  &__logos {
    display: flex;
    align-items: center;
  }

  &__logo-mark {
    width: 104px;
    margin-right: 32px;
  }

  &__logo-text {
    width: 312px;
  }

  &__bottom {
    padding: 40px 16px 32px;
    background: $gray-darken-3;
  }

  &__bottom-inner {
    // max-width: $base-content-width;
    max-width: 1245px;
    margin: 0 auto;
  }

  @include media-pc {

    &__sp-expand {
      display: none;
    }

    &__pagetop {
      &:hover {
        &::after {
          color: $yellow;
        }
      }
    }
  }

  @include media-sp {

    &__pagetop {
      position: fixed !important;
      bottom: 20px !important;
      width: 44px;
      height: 44px;
    }

    &__sp-expand {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 54px;
      border-top: 1px solid $gray-darken-2;
      border-bottom: 1px solid $gray-darken-2;
      background: $gray-lightest;

      &::after {
        @include centering(false, true);
        right: 12px;
        color: $gray-darken-2;
        font-family: 'nssol-icon';
        content: "\e90f";
      }

      &.-active {
        &::after {
          content: "\e90e";
        }
      }
    }

    &__top {
      display: none;
      padding: 32px 16px;
    }

    &__logos {
      margin: 0 auto;
    }

    &__logo-mark {
      width: 80px;
      margin-right: 16px;
    }

    &__logo-text {
      width: 100%;


      img {
        width: 192px;
        height: 14px;
      }
    }

    &__copy {
      font-size: 1rem;
    }
  }

}

/* l-footer-sitemap
----------------------------------------------------------- */

.l-footer-sitemap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__col {
    width: 25%;
    padding-right: 4%;
  }

  &__category {
    &:not(:first-child) {
      margin-top: 3.1em;
    }
  }

  &__category-top {
    line-height: 1.2;

    a {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  &__category-list {
    margin-top: 20px;

    > li {
      &:not(:first-child) {
        margin-top: .2em;
      }

      a {
        display: inline-block;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
  }

  @include media-pc {

    &__category-top {
      a:hover {
        text-decoration: underline;
      }
    }

    &__category-list {
      a:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-sp {

    &__col {
      width: 48%;
      padding-right: 0;

      &:nth-child(n + 3) {
        margin-top: 24px;
      }
    }

    &__category {
    }

    &__category-top {
      a {
        font-size: 1.6rem;
      }
    }

    &__category-list {
      > li {
        a {
          font-size: 1.2rem;
        }
      }

    }
  }
}

/* l-footer-bottom
----------------------------------------------------------- */
.l-footer-bottom {
  &__nav-list {
    display: flex;

    > li {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $white;
        font-size: 1.4rem;
      }
    }
  }

  &__copyright {
    margin-top: 24px;
    color: rgba($white, .5);
  }

  @include media-pc {
    &__nav-list {
      > li {
        a:hover {
          text-decoration: underline;
        }
      }
    }

    &__logo {
      display: none;
    }
  }

  @include media-sp {
    &__nav-list {
      flex-direction: column;

      > li {

        &:not(:first-child) {
          margin-top: 8px;
          margin-left: 0;
        }

        a {
          font-size: 1.2rem;
        }
      }
    }

    &__logo {
      display: block;
      max-width: 128px;
      margin: 28px auto 0;
    }

    &__copyright {
      margin-top: 16px;
      color: $white;
      text-align: center;
      line-height: 1.4;
    }
  }
}