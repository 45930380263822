.c-box {
  display: block;
  width: 100%;
  padding: 20px;

  + .c-box {
    margin-top: 32px;
  }

  &.-white {
    background: $white;
  }

  &.-gray {
    background: $gray-lighten-3;
  }

  &.-blue {
    background: $light-blue;
  }

  &.-warning {
    background: $bg-warning;
  }

  &.-danger {
    background: $bg-danger;
  }

  &.-bordered {
    border: 1px solid $base-border-color;
  }

  &.-rounded {
    border-radius: 5px;
  }

  @include media-sp {
    padding: 20px 15px;

    + .c-box {
      margin-top: 26px;
    }
  }
}

.c-grid {
  @include media-pc {
    .c-box {
      height: 100%;
    }
  }
}