.ir-news-main {
  .press-list {
    border-top: 0;
    &__item {
      border-bottom: 0;
    }
  }

  .press-item {
    display: flex;
    padding: 16px 0;

    &__header {
      display: block;
      margin-bottom: 0;
    }

    &__body {
      margin-bottom: 0;
      margin-left: 16px;
    }

    .c-label-list,
    .c-label {
      display: none;
    }
  }

  @include media-sp {
    .press-item {
      flex-direction: column;
      padding: 8px 0;

      &__body {
        margin-left: 0;
      }
    }
  }


}

.ir-news-block {

  @include media-pc {
    &.c-grid.-wide {
      margin: 0em -2em 0;

      .c-grid__item {
        padding: 0em 2em;
      }
    }
    .c-grid__item {
      &:last-child {
        border-left: 1px solid $base-border-color;
      }
    }

    &__btn {
      text-align: right;
    }
  }

  &__btn {
    margin-top: 24px;
  }
}

.ir-top-news {
  display: flex;

  &__date {
    min-width: 120px;
    margin-right: 1.5em;
  }

  &:not(:first-child) {
    margin-top: 1.5em;
  }

  @include media-sp {
    flex-direction: column;

    &__date {
      margin-bottom: 4px;
    }
  }
}

span.ir-top-news__link {
  color: $base-font-color;

  &:hover {
    text-decoration: none;
  }
}

.ir-top-info-list {
  display: flex;
    flex-wrap: wrap;
  dt {
    width: 110px;
  }

  dd {
    width: calc(100% - 110px);
  }
}

.ir-top-faq {
  width: 100%;
  margin-bottom: 1.5em;
  padding: 2.5em 2em;
  background: $gray-lighten-3;

  &__list {
    margin-bottom: 1em;
    li {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 1em;
      }
    }
  }

  &__icon {
    margin-right: 20px;
    font-size: 2.4rem;
    font-weight: 700;
  }

  @include media-sp {
    padding: 16px;

    &__icon {
      margin-right: 15px;
    }
  }
}

.ir-chart-label {
  display: flex;
  justify-content: space-between;

  > div {
    margin-bottom: 10px;
  }
}

.ir-chart-table td {
  text-align: right;
}

.ir-chart-container {
  height: 300px;
}