.article {

  &__date {
    margin-bottom: 0;
    color: $txt-gray;
  }

  &__title {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid $base-border-color;
  }

  &__body {

    h2,
    h3,
    h4,
    h5 {
      margin: 1.4em 0 1em;
    }

    h2 {
      margin: 2.2em 0 1em;
    }

    ul {
      list-style-type: disc;
      padding-left: 1.5em;

      >li {
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
    }

    ul,
    ol,
    table {
      margin: 1.5em 0;
    }

    table {
      width: 100%;

      tr {
        border-top: 1px solid $base-border-color;

        &:last-child {
          border-bottom: 1px solid $base-border-color;
        }
      }

      th,
      td {
        padding: 16px;
      }

      th {
        background: $base-bg-gray;
      }
    }

    p {
      margin: 1.5em 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-sp {

    &__title {
      padding-bottom: 8px;
      font-size: 2.4rem;
    }

    &__body {
      h2 {
        font-size: 2rem;
      }

      p {
        margin-bottom: 16px;
      }

      table {

        th,
        td {
          padding: 8px;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.article-logo-box {

  .c-grid__item {
    padding-bottom: 0;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 235px;
    background: $white;
    border: 1px solid $gray-lighten-2;
    padding: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &.-small {
      width: 200px;
      height: 120px;
      padding: 24px;
    }

    &.-large {
      width: 100%;
      max-width: 600px;
      height: 250px;
      padding: 60px;
    }
  }

  @include media-sp {

    &__image {
      &.-small {
        width: 120px;
        height: 70px;
        padding: 16px;
      }

      &.-large {
        height: 120px;
        padding: 30px;
      }
    }
  }
}


.article-profile {
  display: flex;
  align-items: center;

  &__image {
    overflow: hidden;
    z-index: 10;
    max-width: 220px;
    min-width: 220px;
    height: 220px;
    border-radius: 110px;
  }

  &__box {
    position: relative;
    width: 100%;
    padding: 2em;
    background: #E8F8FD;

    p {
      margin: 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: -110px;
      z-index: 0;
      width: 110px;
      height: 100%;
      background: #E8F8FD;
      content: "";
    }
  }

  &__header {
    margin-bottom: 1em;
  }

  &__position,
  &__name {
    display: block;
  }

  &__position {
    font-size: 1.4rem;
  }

  &__name {
    font-size: 2.3rem;
  }

  @include media-sp {
    flex-direction: column;

    &__image {
      min-width: 110px;
      max-width: 110px;
      height: 110px;
    }

    &__box {
      padding: 20px 16px 30px;

      &::before {
        top: -55px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 55px;
      }
    }

    &__position {
      font-size: 1.3rem;
    }

    &__name {
      font-size: 1.6rem;
    }

  }
}
