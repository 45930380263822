/* ssトップの検索結果
----------------------------------------------------------- */
.search-result-list {
  border-top: 1px solid #A0A0A0;

  &__item {
    padding: 1.5em 0 1.875em;
    border-bottom: 1px solid #A0A0A0;
  }

  &__link {
    display: block;
    margin-bottom: 1em;
    color: $marine-blue;
    font-size: 2.3rem;
    line-height: 1.4;
  }

  @include media-pc {
    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-sp {
    &__link {
      font-size: 1.6rem;
    }
  }
}

/* /search.html配下のssブロック
----------------------------------------------------------- */

.search-ss-block {
  padding: 120px 16px;
  background: url(/assets/img/top/bg_ss.jpg) no-repeat top/cover;
  background-attachment: fixed;

  @include media-ie11 {
    background-attachment: scroll;
  }

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    padding: 5%;
    background: $white;
  }

  @include media-sp {
    padding: 32px 16px;
    background-size: initial;

    &__inner {
      flex-direction: column;
    }
  }

  .ss-search-tab__item-list {
    margin: 0 -1.2% -1.2%;
  }

  .ss-search__tab,
  .ss-search__form {
    max-width: 920px;
    margin: 0 auto;
  }
}
