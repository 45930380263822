.ss-search {
  width: 100%;

  &__form {

    form {
      overflow: hidden;
      display: flex;
      border-radius: 6px;
      border: 1px solid #a0a0a0;
      background: $white;
    }
  }

  &__input {
    width: 100%;
    height: 70px;
    padding: 20px;
    border: none;
    @include transition;

    &::placeholder {
      color: $gray-darken-2;
    }

    &:focus {
      background: #E8F8FE;
    }
  }

  &__search-btn {
    width: 80px;
    border-left: 1px solid #a0a0a0;
    background: $white;

    .icon-search {
      span {
        position: absolute;
        text-indent: -9999px;
      }

      &::before {
        color: $cyan;
        font-size: 2.2rem;
      }
    }
  }

  @include media-sp {
    width: 100%;
    margin-bottom: 14px;
    padding: 24px 10px;

    &__input {
      height: 48px;
      padding: 12px;
      font-size: 1.6rem;
    }

    &__search-btn {
      width: 44px;

      .icon-search {
        &::before {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.ss-search-tab {
  &__list {
    display: flex;
    margin-bottom: 18px;
  }

  &__btn {
    width: 50%;
    padding-bottom: 18px;
    border-bottom: 4px solid $gray-lighten-1;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.4;
    cursor: pointer;
    @include transition;

    &:hover {
      border-bottom: 4px solid rgba($cyan, .4);
    }

    &.-active {
      border-color: $cyan;
    }
  }

  &__panel {
    display: none;
    padding-bottom: 40px;

    &:first-child {
      display: block;
    }
  }

  &__item-list {
    display: flex;
    flex-wrap: wrap;

    >li {
      width: calc(100% / 3);
      padding: 1.2%;
    }
  }

  &__item-link {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 70px;
    padding: 8% 16% 8% 8%;
    border-radius: 5px;
    border: 1px solid #a0a0a0;
    background: $white;
    line-height: 1.4;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($gray, .5);
    }

    &::before {
      @include btn-cover;
      background-color: rgba($black, 0.08);
    }

    .icon-triangle-right {
      overflow: hidden;
      @include centering(false, true);
      right: 2.5%;

      &::before {
        color: $gray-darken-1;
        transform: translateX(0);
        @include transition;
      }
    }
  }

  @include media-pc {
    &__item-list {
      &.-col-3 {
        >li {
          width: 33.3%;
        }
      }

      &.-col-5 {
        >li {
          width: 20%;
        }
      }
    }

    &__item-link {
      &:hover {
        border-color: $gray;

        &::before {
          @include btn-animation;
        }

        .icon-triangle-right {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }
  }

  @include media-sp {

    &__btn {
      padding-bottom: 6px;
      font-size: 1.3rem;
      letter-spacing: -0.01em;
    }

    &__panel {
      min-height: inherit;
      padding-bottom: 20px;
    }

    &__item-list {
      margin: -4px;

      >li {
        width: 50%;
        padding: 4px;
      }
    }

    &__item-link {
      min-height: 48px;
      height: 100%;
      padding: 12px 18px 12px 12px;
      font-size: 1.2rem;

      &::after {
        right: 4px;
      }
    }
  }
}

/* ss-production-links-sp
----------------------------------------------------------- */

.ss-production-links-sp {
  @include media-pc {
    display: none;
  }

  &__pinch-out {
    display: flex;
    align-items: center;

    img {
      width: 24px;
    }

    span {
      margin-left: 8px;
    }
  }
}

/* ss-production-links
----------------------------------------------------------- */

.ss-production-links-sec01 {
  height: 252px;
}

.ss-production-links-sec02 {
  height: 490px;
  margin-top: 10px;
}

.ss-production-links-sec04 {
  display: flex;
  justify-content: space-between;

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ss-production-btn {
      margin-top: 10px;
      width: calc((100% - 18px) / 2);
      min-width: 49%;

      &.-w100p {
        width: 100%;
      }

    }
  }

  .ss-production-links__box-02 {
    justify-content: flex-start;
    width: 32.6%;
    height: 236px;

    >p {
      margin-bottom: .75em;
      line-height: 1.3;
      font-weight: 500;
    }
  }
}

.ss-production-links {
  @include media-sp {
    display: none;
  }

  position: relative;
  height: 1210px;

  &__inner {
    margin-left: 48px;
  }

  &__box-01 {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #FDE22E;
    border-radius: 8px;
    background: #FFFCE5;

  }

  &__box-01-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
  }

  &__box-01-col-center {
    width: 47%;
  }

  &__box-02 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid#FBBB46;
    background: #FFF691;
  }

  &__box-03 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 164px;
    padding: 10px 10px 10px 20px;
  }

  &__box-03-col {

    &:first-child {
      display: flex;
      width: 75%;

      .ss-production-btn {
        width: 21%;
        min-width: inherit;
        margin-right: 10px;

        &:first-child {
          width: 31%;
          margin-right: 20px;

          @include media-tablet {
            margin-right: 10px;
          }
        }
      }
    }

    &:last-child {
      width: 25%;
      height: 144px;

      .ss-production-btn {
        height: 58px;

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.928em;
    height: 50px;
    border-radius: 50px;
    background: $white;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.3;

    &.-large {
      width: 7.428em;
    }
  }
}

/* ss-production-btn
----------------------------------------------------------- */

.ss-production-btn {
  justify-content: flex-start;
  min-height: 58px;
  padding-right: 2em;
  padding-left: 1.2em;

  &.-h83 {
    height: 83px;
  }

  &.-h85 {
    height: 85px;
  }

  &.-h112 {
    height: 112px;
  }

  &.-h124 {
    height: 124px;
  }

  &.-h138 {
    height: 138px;
  }

  &.-h144 {
    height: 144px;
  }

  &.-h160 {
    height: 160px;
  }

  &.-h230 {
    height: 230px;
  }

  &.-strong {
    min-height: inherit;
    height: 52px;
    font-weight: 700;
  }
}

/* ss-production-links-top
----------------------------------------------------------- */

.ss-production-links-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-left: 48px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background: #447EEC;
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;

    br {
      display: none;
    }

    @include media-tablet {
      br {
        display: block;
      }
    }

    &:nth-child(1) {
      width: 26%;
    }

    &:nth-child(2) {
      width: 46%;
    }

    &:nth-child(3) {
      width: 26%;
    }
  }
}

/* ss-production-links-left
----------------------------------------------------------- */

.ss-production-links-left {
  position: absolute;
  top: 53px;
  left: 0;
  width: 38px;

  &__title {
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    letter-spacing: .15em;
    writing-mode: vertical-lr;

    &:nth-child(1) {
      height: 564px;
      background: #3A51A3;
    }

    &:nth-child(2) {
      height: 336px;
      background: #F87120;
    }

    &:nth-child(3) {
      height: 236px;
      background: #4FA000;
    }
  }
}

/* ss-production-links-engineering
----------------------------------------------------------- */

.ss-production-links-engineering {
  @include centering(true, false);
  top: -178px;
  z-index: 5;
  width: 190px;
  height: 342px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #DCF7A3;

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 95px 0 95px;
    border-color: #DCF7A3 transparent transparent transparent;
    content: "";
  }

  .ss-production-btn {
    width: 100%;
    min-width: inherit;
  }

  &__labels {
    @include centering(true, false);
    z-index: 10;

    span {
      margin: 8px auto 0;
    }
  }
}

/*  ss-production-links-enterprise,  ss-production-links-execution
----------------------------------------------------------- */

.ss-production-links-enterprise,
.ss-production-links-execution {
  position: relative;
  height: 142px;
  padding: 10px;

  .ss-production-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    width: 20%;
    min-width: 150px;

    @include media-tablet {
      min-width: 120px;
    }
  }

  &__labels {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 8;
    width: 100%;

    span {
      margin: 0 4px;
    }
  }
}

.ss-production-links-enterprise {
  width: calc(100% - 20px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #CBE5FF;

  &::after {
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 71px 0 71px 20px;
    border-color: transparent transparent transparent #CBE5FF;
    content: "";
  }

  &__labels {
    width: calc(100% + 20px);
  }
}

.ss-production-links-execution {
  margin-top: 8px;
  border-radius: 8px;
  background: #FFCBCB;
}

.ss-production-links-others {
  display: flex;
  align-items: center;
  margin-top: 10px;

  &__title {
    min-width: 66px;
    margin: 0 14px 0 0;
    font-weight: 500;
    line-height: 1.4;
  }

  &__kaikei,
  &__infura {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .ss-production-btn {
      min-width: inherit;
    }
  }

  &__kaikei {
    .ss-production-btn {
      width: 49%;
    }
  }

  &__infura {
    .ss-production-btn {
      width: 49%;
      height: 100px;
    }
  }
}

/*  ss-iox-link
----------------------------------------------------------- */

.ss-iox-links {

  @media (min-width: 768px) and (max-width: 870px) {
    margin-right: 600px;
    transform: scale(.85);
  }

  @include media-sp {
    display: none;
  }

  &__inner {
    position: relative;
    width: 850px;
    height: 737px;
    background: url(/assets/img/ss/iox/img_iox_graph_02.png) no-repeat;
    background-size: contain;
    margin: auto;
  }

  &__btn {
    position: absolute;
    display: block;

    &:hover,
    &:focus {
      background: rgba($white, .3);
    }

    span {
      @include sr-only;
    }

    &.-integration {
      width: 456px;
      height: 51px;
      left: 70px;
      top: 234px;
    }

    &.-anzenmimamori01 {
      width: 135px;
      height: 51px;
      left: 262px;
      bottom: 271px;
    }

    &.-anzenmimamori02 {
      width: 135px;
      height: 51px;
      left: 340px;
      bottom: 8px;
    }

    &.-arpatio {
      width: 135px;
      height: 51px;
      right: 297px;
      bottom: 271px;
    }

    &.-arpicut {
      width: 136px;
      height: 51px;
      right: 67px;
      bottom: 271px;
    }

    &.-rfid {
      width: 135px;
      height: 51px;
      right: 297px;
      bottom: 203px;
    }

    &.-predictive-maintenance {
      width: 135px;
      height: 51px;
      left: 340px;
      bottom: 103px;
    }
  }
}

.ss-iox-pf-plus {
  color: #0a6ea0;
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1;
}

.ss-iox-pf-links {

  @include media-pc {
    padding: 2em;
    background: $white;
  }

  &__image {
    position: relative;
    margin: 0 auto 2em;
    background: url(/assets/img/ss/iox/pf_img01.png);
    width: 850px;
    height: 514px;
  }

  &__inner {
    @include media-sp {
      display: none;
    }

    @media (min-width: 768px) and (max-width: 920px) {
      margin-right: 600px;
      transform: scale(.8);
    }
  }

  &__btn {
    position: absolute;
    display: block;
    transition: 0.3s;

    &:hover {
      background: rgba(255,255,255,0.4);
    }

    &.-datarobot {
      width: 146px;
      height: 55px;
      top: 107px;
      left: 94px;
    }

    &.-mnubo {
      margin: auto;
      width: 145px;
      height: 55px;
      top: 107px;
      left: 0;
      right: 0;
    }

    &.-foghorn {
      margin: auto;
      width: 146px;
      height: 55px;
      bottom: 58px;
      left: 0px;
      right: 18px;
    }

    &.-iox-pf {
      width: 201px;
      height: 86px;
      bottom: 103px;
      left: 55px;
    }

    &.-predictive-maintenance {
      margin: auto;
      width: 186px;
      height: 63px;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 40px;
    }

    &.-thingworx {
      width: 146px;
      height: 55px;
      bottom: 208px;
      right: 169px;
    }

    &.-iox-pf {
      width: 201px;
      height: 86px;
      bottom: 103px;
      left: 55px;
    }
  }
}


/* ss-top-search
----------------------------------------------------------- */
.ss-top-search {
  &__input {
    max-width: 900px;
    margin: 30px auto 0;
  }
}

/* ss-top-search
----------------------------------------------------------- */
.ss-top-image-link {
  margin-bottom: 3em;

  @include media-pc {
    > li {
      &:nth-child(n + 4) {
        margin-top: 1.5em;
      }
    }
  }
}

/* ss-top-pict-list
----------------------------------------------------------- */
.ss-top-pict-list {
  $this: &;
  display: flex;
  flex-wrap: wrap;

  &__item {
    padding: 0 1.75em;

    .c-link {
      float: right;
    }

    p {
      margin-bottom: 2em;
    }
  }

  &__item-link {
    overflow: hidden;
    display: block;

    &:hover {
      .c-link {
        text-decoration: underline;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    margin-bottom: 24px;
  }

  @include media-pc {

    &__item {
      width: 33.3%;
      border-right: 1px solid #A0A0A0;

      &:nth-child(3n) {
        border-right: 0;
      }

      &:nth-child(n + 4) {
        margin-top: 5em;
      }
    }

    &__image {
      img {
        transition: all 0.2s ease-in-out;
      }
    }

    &__item-link {
      &:hover {
        #{$this}__image {
          img {
            transform: scale(1.09);
          }
        }
      }
    }
  }

  @include media-sp {
    &__item {
      padding: 0;

      &:not(:first-child) {
        margin-top: 48px;
      }
    }
  }
}


.ss-top-seminar-info {
  display: flex;
  justify-content: center;
  padding: 4em 2em;
  border: 1px solid #D4D4D4;

  &__inner {
    max-width: 620px;
  }

  &__box {
    margin-bottom: 2.75em;
  }

  &__link-box {
    a {
      display: block;
    }

    span {
      display: block;
      padding-left: 1.714em;
      font-size: 1.4rem;
    }
  }

  &__data {
    margin-bottom: 1.25em;

    dt,
    dd {
      margin-top: 5px;
    }
  }

  @include media-pc {

    &__box {
      display: flex;
    }

    &__labels {
      margin-right: 1.875em;
      padding-right: 1.875em;
      border-right: 1px solid #A0A0A0;

      .c-label {
        display: block;
        + .c-label {
          margin-top: 10px;
        }
      }
    }

    &__data {
      display: flex;
      flex-wrap: wrap;

      dt {
        width: 16%;
      }

      dd {
        width: 84%;
      }
    }
  }

  @include media-sp {
    padding: 24px 16px;

    &__link-box {
      span {
        padding-left: 21px;
      }
    }

    &__labels {
      margin-bottom: 16px;
      .c-label {
        + .c-label {
          margin-left: 8px;
        }
      }
    }
  }
}

.ss-global-talk {
  padding: 1.5em 1.5em 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #A0A0A0;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__sub-title {
    margin-bottom: 12px;
  }

  &__date {
    font-size: 1.8rem;
  }

  &__items {
    display: flex;
    padding: 1.5em 0 2.5em;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 1em;
  }

  &__photo {
    margin-right: 2.5em;
  }

  @include media-sp {
    padding: 1.5em 0 0;

    &__date {
      font-size: 1.4rem;
    }

    &__items {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      padding-right: 0;

      &:first-of-type {
        margin-bottom: 20px;
      }
    }

    &__photo {
      min-width: 80px;
      max-width: 80px;
      margin-right: 20px;
    }
  }
}

/* /ss/5g/ページ用追加スタイル */

.w-850 {
  max-width: 850px;
  margin: auto;
}

.page-ss-5g .l-pagetitle {
  padding: 32px 16px 40px;
}