.l-search {
  overflow-y: auto;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  padding: 60px 16px;
  background: $white url(/assets/img/common/bg_search.png) no-repeat center/cover;
  text-align: left;

  &__inner {
    max-width: 900px;
    height: 100%;
    margin: 0 auto;

    p {
      font-size: 2.4rem;
    }
  }

  &__title {
    font-size: 2.3rem;
  }

  &__site-search {
    margin-bottom: 60px;
  }

  &__input {
    margin-bottom: 20px;

    input {
      width: 100%;
      padding: 20px 24px;
      border: 1px solid #A0A0A0;
      border-radius: 6px;
      @include transition;

      &:focus {
        background: #E8F8FE;
      }
    }
  }

  &__btn {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    padding: 24px;
    border: 1px solid #A0A0A0;
    border-radius: 6px;
    background: $white;
    font-size: 2.2rem;
    font-weight: 500;

    &::before {
      @include btn-cover;
      background-color: rgba($black, 0.08);
    }

    &::after {
      @include centering(false, true);
      right: 18px;
      color: $cyan;
      font-family: 'nssol-icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "\e907";
    }
  }

  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: $white;

    span {
      display: inline-block;
      width: 0;
      height: 0;
      text-indent: -9999px;
    }

    &::after {
      color: $gray-darken-1;
      font-family: "nssol-icon";
      font-size: 3rem;
      content: "\e908";
    }
  }

  &__close-bottom {
    text-align: center;
    margin-top: 80px;
    padding-bottom: 60px;
  }

  @include media-pc {
    &__btn {
      min-width: 320px;
      &:hover {
        &::before {
          @include btn-animation;
        }
      }
    }
  }

  @include media-sp {

    &__site-search {
      margin-bottom: 32px;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__input {
      input {
        padding: 15px 12px;
        font-size: 1.6rem;
      }
    }

    &__btn {
      width: 100%;
      margin-top: 32px;
      padding: 18px;
      font-size: 1.6rem;
    }

    &__close-btn {
      top: 12px;
      right: 12px;
    }

    &__close-bottom {
      margin-top: 20px;
      padding-bottom: 40px;
    }
  }
}