.c-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba($black, .4);
  transition: all 1s;

  &.-loaded {
    opacity: 0;
    visibility: hidden;
  }

  &__spinner {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    border-top: 8px solid #DDF5FD;
    border-right: 8px solid #DDF5FD;
    border-bottom: 8px solid #DDF5FD;
    border-left: 8px solid #1EB9EE;
    font-size: 10px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;

    @include media-sp {
      width: 50px;
      height: 50px;
      border-width: 5px;
    }
  }
}