.form-step {
  position: relative;
  display: flex;
  margin-bottom: 3em;

  @include media-sp {
    display: none;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 33.3%;
    height: 72px;
    padding: 14px;
    border: 1px solid #A0A0A0;
    background: $white;
    font-size: 2.3rem;
    font-weight: 500;
    text-align: center;

    &:not(:last-child) {
      border-right: 0;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        right: -21px;
        z-index: 5;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 20px;
        border-color: transparent transparent transparent $white;
        content: "";
      }

      &::before {
        border-color: transparent transparent transparent #A0A0A0;
      }

      &::after {
        top: 0;
        right: -20px;
      }
    }

    &.-current {
      background: #FFF7B5;

      &::after {
        border-color: transparent transparent transparent #FFF7B5;
      }
    }
  }
}

.form-table {
  + .form-table {
    margin-top: 2.5em;
  }

  .c-form__title {
    width: 30%;
  }

  @include media-sp {
    .c-form,
    .c-form__title,
    th,
    td {
      display: block;
      width: 100%;
    }

    td {
      border-top: 0;
    }

    tr {
      margin-top: -1px;
      border-top: 0;
    }
  }

}

.form-table {
  + .form-table-02 {
    margin-top: -1px;
  }
}

.form-table-02 {
  width: 100%;

  th,
  td {
    padding: 1.5em;
    border: 1px solid #a0a0a0;
  }

  th {
    background: #E8E8E8;
    font-weight: 500;
    line-height: 1.4;
  }

  td {
    select {
      width: 400px;
      padding: 13px 32px 13px 17px;
      border-radius: 6px;
      border: 1px solid #A0A0A0;
      background: $white;
      cursor: pointer;
    }
  }

  .select {
    position: relative;
    width: 400px;
  }

  @include media-sp {
    th,
    td {
      display: block;
      width: 100%;
      padding: 14px 12px;
      font-size: 1.4rem;
    }

    td {
      border-top: 0;

      select {
        width: 100%;
        padding: 10px 32px 10px 17px;
        font-size: 1.6rem;
      }
    }
  }
}

.form-error-message {
  display: none;
  padding: 1.5em;
  border: 1px solid $red;

  p {
    color: $red;
    font-weight: 700;
  }

  @include media-pc {
    p {
      text-align: center;
    }
  }
}

.form-btn {
  display: flex;
  justify-content: center;
  margin-top: 3.75em;

  .c-btn {
    margin: 0 12px;
  }

  @include media-sp {
    margin-top: 3em;
  }

  @include media-sp-xs {
    flex-direction: column;

    .c-btn {
      margin: 12px 0;
    }
  }
}

.form-privacy {

  &__ask {
    padding: 24px;
    border: 1px solid #a0a0a0;
    border-top: 0;
    background: #DDF5FD;
    font-weight: 700;
    text-align: center;

    + div {
      padding: 24px;
      border: 1px solid #a0a0a0;
      border-top: 0;
      background: $white;
      text-align: center;

    }
  }

  @include media-sp {
    &__ask {
      padding: 16px 24px;
    }
  }
}