.c-table {
  table-layout: fixed;
  width: 100%;

  &.-center {
    th,
    td {
      text-align: center;
    }
  }

  .w-30 {
    width: 30%;
  }

  th,
  td {
    padding: 1.5em;
    border: 1px solid #a0a0a0;
  }

  th {
    background: #E8E8E8;
    font-weight: 500;
  }

  td {
    background: $white;
  }

  tbody {

    th {
      width: 25%;
    }
  }

  &.-col-1to2 {
    th,
    td {
      &:first-child {
        width: 33.3%;
        text-align: center;
      }
    }
  }

  &.-col-2 {
    th {
      width: 50%;
    }

    td {
      text-align: center;
    }
  }

  &.-col-3 {
    th {
      width: 33.3%;
    }

    td {
      text-align: center;
    }
  }

  &.-no-border {
    tr,
    th,
    td {
      padding: 1em 1.5em 1em 0;
      border: 0;
      background: $white;
      text-align: left;
    }
  }

  @include media-sp {

      th,
      td {
        padding: 14px 12px;
        font-size: 1.4rem;

      }

      th {
        line-height: 1.4;
      }

      td {
        line-height: 1.6;
      }

      tbody {

        th {
          width: 28%;
        }
      }
  }
}

.c-table-scroll {

  @include media-sp {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    .c-table {
      width: 100%;
      min-width: 767px;
    }
  }
}