/* ============================================================ */
/* settings */
/* ============================================================ */

/* colors
----------------------------------------------------------- */
$white: #fff;
$black: #000;

$gray-lightest: #FCFCFC;

$gray-lighten-3: #E8E8E8;
$gray-lighten-2: #D4D4D4;
$gray-lighten-1: #CECECE;
$gray: #A2A2A2;
$gray-darken-1: #868686;
$gray-darken-2: #707070;
$gray-darken-3: #606060;

$marine-blue: #095C78;
$ink-blue: #003398;
$cyan: #1EB9EE;
$light-blue: #DDF5FD;
$green: #04B787;
$yellow: #F1D900;
$light-yellow: #FFF388;


$blue: #0066ba;
$red: #FF0000;

$base-font-color: #111;
$base-border-color: #dbdcdf;
$base-bg-gray: #eef0f3;

$border-dark: #dbdcdf;


$bg-warning: #fffedf;
$bg-danger: #FFEDED;

$txt-gray: #6f6f6f;

/* width
----------------------------------------------------------- */
$base-content-width: 1236px;
$content-narrow: 720px;

/* box-shadow
----------------------------------------------------------- */
$base-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

/* font
----------------------------------------------------------- */

/*----- base-font-family -----*/

$base-font-family: 'Noto Sans JP', sans-serif;

/*----- base-font-family (serif) -----*/

/*$font-serif: 'Hiragino Mincho ProN', 'Yu Mincho', 游明朝, 'HGS明朝E', serif;*/