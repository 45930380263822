.c-carousel {

  .slick-arrow {
    position: absolute;
    top: 42%;
    z-index: 10;
    width: 24px;
    height: 44px;
    background-size: contain;
    text-indent: -9999px;
    content: "";

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .slick-prev {
    left: -4%;
    background: url(/assets/img/top/ico_slide_prev.svg) no-repeat top/contain;
  }

  .slick-next {
    right: -4%;
    background: url(/assets/img/top/ico_slide_next.svg) no-repeat top/contain;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    >li {
      position: relative;
      width: 80px;
        height: 2px;
      margin: 0 8px;
      background: $gray-lighten-1;

      >button {
        opacity: 0;
        position: absolute;
        top: -18px;
        width: 100%;
        text-indent: -9999px;
      }

      &.slick-active {
        height: 4px;
        margin-top: -1px;
        background: $cyan;
      }
    }
  }

  @media (max-width: 1280px) {
    .slick-next {
      right: 1%;
    }

    .slick-prev {
      left: 1%;
    }
  }



  @include media-sp {

    .slick-arrow {
      opacity: 0;
    }

    .slick-dots {
      margin-top: 32px;

      >li {
        width: 40px;

        button {
          top: -14px;
          height: 32px;
        }
      }
    }
  }
}

.c-carousel-list-01 {
  margin: 0 -1em;

  .slick-slide {
    user-select: text;
  }
}

.c-carousel-item-01 {
  padding: 0 1em;

  &__image {
    position: relative;
    margin-bottom: 1em;
  }

  &__link {
    position: relative;
    overflow: hidden;
    display: block;
    position: relative;
    margin-bottom: 1em;
    background: $black;

    &[target="_blank"] {
      &::after {
        position: absolute;
        top: 1em;
        right: 1em;
        display: inline-block;
        color: $white;
        font-family: 'nssol-icon';
        line-height: 1;
        content: "\e910";
      }
    }

    img {
      transition: all 0.2s ease-in-out;
    }
  }

  &__text {
    position: absolute;
    bottom: 20px;
    left: 25px;
    z-index: 10;
    padding-right: 1em;
  }

  &__title {
    margin-bottom: 10px;
    color: $white;
    font-size: 3.2rem;
    font-weight: 700;
    text-shadow: 0 0 4px rgba(1, 6, 69, 0.5);
    line-height: 1.3;
  }

  &__sub-title {
    color: $white;
    font-size: 1.9rem;
    font-weight: 500;
    text-shadow: 0 0 4px rgba(1, 6, 69, 0.5);
    line-height: 1.5;
  }

  @include media-pc {

    &__link {
      &:hover {
        img {
          transform: scale(1.07);
          opacity: .9;
        }
      }
    }
  }

  @include media-sp {

    &__text {
      bottom: 15px;
      left: 15px;
    }

    &__title {

      font-size: 1.7rem;
    }

    &__sub-title {
      font-size: 1.4rem;
    }
  }
}

.c-carousel-list-02 {



  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#f9f9f9, .9);
      content: "";
      transition: all .3s;
    }
  }

  .slick-active {
    &::after {
      background: transparent;
      opacity: 0;
      height: 0;
    }
  }

  @include media-sp {
    width: 93%;
    margin-left: 0;

    &.c-grid {
      margin: 0;
    }

  .c-grid__item {
    padding: 0;
  }
  }

}