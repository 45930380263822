.c-overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgba($black, .25);
  @include transition;

  &.-visible {
    visibility: visible;
    opacity: 1;
  }

  @include media-sp {

    &.-visible {
      opacity: 0;
    }
  }
}