.l-breadcrumb {
  width: 100%;
  background: #F7F7F7;

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: $base-content-width;
    margin: 0 auto;
    padding: 12px;
  }

  &__item {
    position: relative;
    font-size: 1.4rem;

    &:not(:first-child) {
      margin-left: 18px;
      padding-left: 20px;
    }

    &:not(:first-child)::before {
      position: absolute;
      top: 10px;
      left: -4px;
      width: 6px;
      height: 6px;
      border-right: 0.5px solid $gray-darken-1;
      border-bottom: 0.5px solid $gray-darken-1;
      transform: rotate(-45deg);
      content: "";
    }

    >a:hover {
      text-decoration: underline;
    }
  }

  @include media-sp {
    overflow-x: auto;

    &__list {
      flex-wrap: nowrap;
      padding: 8px;
      white-space: nowrap;
    }

    &__item {
      font-size: 1.2rem;

      &:not(:first-child) {
        margin-left: 12px;
        padding-left: 12px;
      }

      &:not(:first-child)::before {
        top: 8px;
        left: -4px;
        width: 6px;
        height: 6px;
      }

      span {
        padding-right: 8px;
      }
    }
  }

}