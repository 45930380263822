/* top-hero-scroll
---------------------------- */
@keyframes top-hero-scroll {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

/* top-hero-fadein
---------------------------- */

@keyframes top-hero-fadein {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* top-btn-line
---------------------------- */

@keyframes top-btn-line {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }

  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

/* arrow-slide
---------------------------- */

@keyframes arrow-slide {
  0% {
      transform: translateX(0);
  }

  50% {
      transform: translateX(100%);
  }

  50.1% {
      transform: translateX(-100%);
  }

  100% {
      transform: translateX(0);
  }
}

/* arrow-slide-bottom
---------------------------- */

@keyframes arrow-slide-bottom {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(100%);
  }

  50.1% {
      transform: translateY(-100%);
  }

  100% {
      transform: translateY(0);
  }
}

/* hoverTranslate
---------------------------- */

@keyframes hoverTranslate {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  .1% {
    opacity: 1;
  }

  80% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
  }
}

/* spinner
---------------------------- */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}