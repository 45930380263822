@charset "utf-8";

/* Base
----------------------------------------------------------- */
@import "base/_mixins";
@import "base/_variables";
@import "base/_normalize";
@import "base/_base";
@import "base/_keyframes";

/* Layout ------------ */
@import "layout/_breadcrumb.scss";
@import "layout/_footer.scss";
@import "layout/_future.scss";
@import "layout/_gnav.scss";
@import "layout/_header.scss";
@import "layout/_layout.scss";
@import "layout/_lnav.scss";
@import "layout/_pagetitle.scss";
@import "layout/_search.scss";

/* Component ------------ */
@import "component/_accordion.scss";
@import "component/_box.scss";
@import "component/_button.scss";
@import "component/_card.scss";
@import "component/_carousel.scss";
@import "component/_checkbox.scss";
@import "component/_cookie.scss";
@import "component/_form.scss";
@import "component/_grid.scss";
@import "component/_heading.scss";
@import "component/_icon.scss";
@import "component/_image.scss";
@import "component/_label.scss";
@import "component/_link.scss";
@import "component/_list.scss";
@import "component/_loader.scss";
@import "component/_movie.scss";
@import "component/_overlay.scss";
@import "component/_search.scss";
@import "component/_section.scss";
@import "component/_sentence.scss";
@import "component/_tab.scss";
@import "component/_table.scss";
@import "component/_tag.scss";
@import "component/_video.scss";

/* Project ------------ */
@import "project/_article.scss";
@import "project/_casestudy.scss";
@import "project/_corporate.scss";
@import "project/_form.scss";
@import "project/_ir.scss";
@import "project/_news.scss";
@import "project/_notices.scss";
@import "project/_post.scss";
@import "project/_press.scss";
@import "project/_search.scss";
@import "project/_seminar.scss";
@import "project/_sitemap.scss";
@import "project/_ss.scss";
@import "project/_stories.scss";
@import "project/_sustainability.scss";
@import "project/_top.scss";

/* Utility ------------ */
@import "utility/_utility.scss";

/* Oldsite import ------------ */
@import "oldstyle/_press.scss";

@media print {
  @import "_print";
}
