.c-btns {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  &__item {
    margin: 12px;
  }

  @include media-sp {

    &__item {
      width: 100%;

      .c-btn {
        margin: 0 auto;
      }
    }
  }
}

.c-btn-block {
  display: flex;
  justify-content: center;
  margin-top: 56px;

  @include media-sp {
    margin-top: 28px;
  }
}

/* c-btn
----------------------------------------------------------- */

.c-btn {
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 150px;
  padding: 13px 36px;
  border-radius: 4px;
  background: #d8dee4;
  color: $base-font-color;
  font-size: 1.6rem;
  line-height: 1.4;
  text-decoration: none;
  @include transition;

  &__icon {
    @include centering(false, true);
    right: 11px;
    overflow: hidden;
    display: inline-block;
    width: 12px;
    height: 16px;

    &::after {
      position: absolute;
      right: 0;
      color: $gray-darken-1;
      font-family: 'nssol-icon' !important;
      font-size: 1.2rem;
      @include transition;
      transform: translateX(0);
      content: "\e90b";
    }

    &.-bottom {
      width: 16px;
      &::after {
        content: "\e909";
      }
    }
  }

  &.-open-new,
  &[target="_blank"] {
    .c-btn__icon {

      &::after {
        color: #4A4A4A;
        content: "\e910";
      }
    }
  }

  &.-more {
    .c-btn__icon {

      &::after {
        content: "\e90f";
      }
    }
  }

  &.-pdf,
  &[href$=".pdf"] {
    .c-btn__icon {
      width: 16px;
      height: 20px;

      &::after {
        content: "\e90d";
        font-size: 1.6rem;
      }
    }
  }

  &.-zip,
  &[href$=".zip"] {
    .c-btn__icon {
      width: 16px;
      height: 20px;

      &::after {
        content: "\e912";
        font-size: 1.6rem;
      }
    }
  }

  &[href^="mailto:"] {
    .c-btn__icon {
      &::after {
        content: "\e911";
      }
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($gray, .5);
  }

  &::before {
    @include btn-cover;
    background-color: rgba($black, 0.08);
  }

  &.-white-outline {
    border: 1px solid #a0a0a0;
    background: $white;
  }

  &.-blue {
    background: $cyan;
    color: $white;

    .c-btn__icon {
      &::after {
        color: $white;
      }
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($cyan, .5);
    }
  }

  &.-small {
    font-size: 1.4rem;
  }

  &.-medium {
    min-width: 240px;
  }

  &.-max {
    width: 100%;
    min-width: inherit;
  }

  &.-disabled {
    background: $gray-lighten-3;
    color: $gray-darken-1;
    pointer-events: none;

    &:hover {
      cursor: default;
    }
  }

  @include media-pc {

    &:hover {

      .c-btn__icon {
        &::after {
          color: $yellow;
          @include arrow-slide;
        }
      }

      .c-btn__icon.-bottom {
        &::after {
          @include arrow-slide-bottom;
        }
      }

      &::before {
        @include btn-animation;
      }
    }

    &.-blue {
      &:hover {
        .c-btn__icon {
          &::after {
            color: $white;
          }
        }
      }
    }

    &.-large {
      min-width: 300px;
      height: 70px;
      padding: 12px 36px;
      font-size: 2rem;

      &.-max {
        min-width: inherit;
      }

      .c-btn__icon {
        right: 12px;
        width: 18px;
        height: 25px;

        &::after {
          font-size: 1.8rem;
        }
      }
    }
  }

  @include media-tablet {
    &__icon {
      &::after {
        top: 5px;
      }
    }

    &.-small {
      .c-btn__icon {
        &::after {
          top: 2px;
        }
      }
    }
  }

  @include media-sp {
    justify-content: flex-start;
    padding: 13px 22px 13px 14px;
    font-size: 1.4rem;

    &__icon {
      right: 4px;

      &::after {
        top: 0;
        font-size: 1rem;
        transform: scale(.9);
      }
    }

    &.-pdf {
      .c-btn__icon {
        width: 14px;
        height: 26px;

        &::after {
          content: "\e90d";
          font-size: 1.4rem;
        }
      }
    }

    &.-zip {
      .c-btn__icon {
        width: 14px;
        height: 26px;

        &::after {
          content: "\e912";
          font-size: 1.4rem;
        }
      }
    }

    &::after {
      right: 10px;
    }

    &.-large {
      width: 100%;
      justify-content: center;

      .c-btn__icon {

        &::after {
          top: 0;
          font-size: 1.2rem;
        }
      }
    }

    &.-sp-max {
      width: 100%;
    }
  }
}

/* c-close-btn
----------------------------------------------------------- */

.c-close-btn {
  overflow: hidden;
  position: relative;
  min-width: 145px;
  padding: 12px 32px;
  border: 1px solid #A0A0A0;
  border-radius: 6px;
  background: $white;
  @include transition;

  &::before {
    @include btn-cover;
    background-color: rgba($black, 0.08);
  }

  &::after {
    @include centering(false, true);
    right: 16px;
    color: $gray-darken-1;
    font-family: 'nssol-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\e908";
  }

  @include media-pc {
    &:hover {

      &::before {
        @include btn-animation;
      }
    }
  }
}