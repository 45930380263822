.press-heading {
  position: relative;

  &__rss {
    display: block;
    margin-bottom: 24px;
    text-align: right;
    font-size: 1.8rem;

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  @include media-sp {
    &__rss {
      font-size: 1.4rem;

      &::after {
        font-size: 1.2rem;
      }
    }
  }
}

.press-list {
  border-top: 1px solid $gray-lighten-2;

  &__item {
    border-bottom: 1px solid $gray-lighten-2;
  }
}

.press-item {
  padding: 32px 0 25px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__date {
    display: block;
    min-width: 120px;
    margin-right: 16px;
  }

  &__body {
    margin-bottom: 24px;
  }

  &__text {
    padding-left: 1.714em;
    font-size: 1.4rem;
  }

  @include media-sp {
    padding: 24px 0 16px;

    &__date {
      min-width: 110px;
      margin-right: 8px;
    }

    &__text {
      padding-left: 1.615;
      font-size: 1.3rem;
    }
  }
}

.press-article {

  &__heading-main {
    margin-bottom: 0;
  }

  &__heading-sub {
    font-size: 2.3rem;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  &__info {
    display: block;
    width: 100%;
    margin-top: 10px;
    text-align: right;

    span {
      display: block;
    }
  }

  &__date {
    min-width: 120px;
    margin-right: 16px;
  }

  &__label {
    &:first-of-type {
      margin-right: 10px;
    }
  }

  &__body {
    margin-bottom: 80px;
  }

  @include media-sp {

    &__heading-sub {
      font-size: 1.4rem;
    }

    &__header {
      margin-bottom: 24px;
    }

    &__label {
      &:first-of-type {
        margin-right: 5px;
      }
    }

    &__date {
      min-width: 110px;
      margin-right: 8px;
    }

    &__body {
      margin-bottom: 40px;
    }

  }
}