.l-noscript {
  position: fixed;
  top: 126px;
  left: 0;
  z-index: 20;
  width: 100%;
  padding: 16px;
  background: $bg-danger;
  text-align: center;

  p {
    line-height: 1.6;
  }

  @include media-sp {
    top: 60px;
  }

  &.-future {
    top: 80px;

    @include media-sp {
      top: 42px;
    }
  }
}

.l-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.l-main {
  padding-top: 126px;
  overflow-x: initial;

  @include media-sp {
    padding-top: 60px;
  }

  &.-bg-image {
    background: url(/assets/img/common/base_pc.png) repeat-y top/cover;

    @include media-sp {
      background: url(/assets/img/common/base_sp.png) repeat-y top/cover;
    }
  }
}

.l-content-block {
  padding: 56px 16px 100px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &.-block-02 {
    padding: 72px 16px;
    background: #F8F8F8;
  }

  &.-block-03 {
    padding: 72px 16px;
    background: $gray-lighten-3;
  }

  &.-block-04 {
    padding: 72px 16px;
    background: #E8F8FD;
  }

  &.-block-05 {
    padding: 48px 16px;
  }

  &.-block-06 {
    padding: 72px 16px;
    background: #F8F8F8;
  }

  @include media-sp {
    padding: 40px 16px 64px;

    &.-block-02 {
      padding: 32px 0;
    }

    &.-block-03,
    &.-block-04,
    &.-block-05,
    &.-block-06 {
      padding: 32px 16px;
    }
  }
}