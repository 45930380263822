.casestudy-search {
  &__checkbox {
    margin-bottom: 30px;
  }

  @include media-sp {
    width: 100%;
    margin-bottom: 14px;
    padding: 24px 10px;
  }
}

.casestudy-search-keyword {
  max-width: 900px;
  margin: 0 auto;
}

.casestudy-header {
  position: relative;
  padding-bottom: 30px;
  font-size: 1.2rem;

  &__rss {
    @include media-pc {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.8rem;
      text-align: center;

      &:hover {
        color: $cyan;
      }
    }

    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  @include media-sp {
    display: flex;
    flex-direction: column-reverse;

    &__rss {
      align-self: flex-end;
      font-size: 1.4rem;

      &::after {
        font-size: 1.2rem;
      }
    }
  }
}

.casestudy-list {
  display: flex;
  flex-wrap: wrap;
  margin: -80px 0 0 -2%;

  &__item {
    margin: 80px 0 0 2%;
    width: 31.33%;
  }

  @include media-sp {
    flex-direction: column;
    margin: -50px 0 0 0;

    &__item {
      margin: 50px 0 0 0;
      width: 100%;
    }
  }
}

.casestudy-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  &__link {
    display: block;
    margin-bottom: 2em;
  }

  &__image {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 235px;
    background: $white;
    border: 1px solid $gray-lighten-2;
    margin-bottom: 20px;
    padding: 1.5em;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      transition: all 0.2s ease-in-out;

      @include media-ie11 {
      }


        @include media-ie11 {
          @media (max-width: 1180px) {
            width: 360px;
        }
      }
    }
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__catch {
    margin-bottom: 16px;
    color: $marine-blue;
  }

  &__date {
    text-align: right;
    margin-bottom: 32px;
  }

  @include media-pc {
    &__link {
      &:hover {
        .casestudy-item__catch {
          text-decoration: underline;
        }

        img {
          transform: scale(1.07);
        }
      }
    }
  }

  @include media-sp {
    &__image {
      height: 174px;
      margin-bottom: 16px;
    }

    &__name {
      font-size: 1.6rem;
      margin-bottom: 10px;
    }

    &__catch {
      margin-bottom: 10px;
    }

    &__date {
      margin-bottom: 20px;
    }
  }
}

.casestudy-list-more {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  @include media-sp {
    margin-top: 50px;
  }
}

.casestudy-detail-company {
  display: flex;
  margin-bottom: 60px;

  &__name {
    margin-left: 30px;
    font-size: 2.3rem;
    padding-top: 10px;
  }

  @include media-sp {
    margin-bottom: 20px;

    &__name {
      margin-left: 10px;
      font-size: 1.4rem;
    }
  }
}

.casestudy-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .c-label-list {
    max-width: 600px;
  }

  &__info {
    display: flex;

    span {
      min-width: 88px;
      margin-right: 20px;
    }
  }

  @include media-tablet {
    span {
      min-width: 75px;
    }
  }

  @include media-sp {
    flex-direction: column;
    align-items: flex-start;

    &__info {
      flex-direction: column;
      margin-bottom: 20px;

      span {
        margin-right: 0;
        margin-bottom: 4px;
      }
    }

    &__btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}


.casestudy-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 235px;
  background: $white;
  border: 1px solid $gray-lighten-2;
  padding: 20px;
  position: relative;

  &.-small {
    width: 250px;
    height: 150px;
    padding: 0;
  }

  &.-large {
    width: 100%;
    max-width: 450px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @include media-pc {
    &.-small {
      img {
        max-width: 220px;
        min-width: 140px;
      }
    }

    &.-large {
      height: 268px;

      img {
        max-width: 300px;
      }
    }
  }

  @include media-tablet {
    &.-large {
      height: 180px;
    }
  }

  @include media-sp {
    padding: 12px;

    &.-small {
      padding: 0;
      width: 120px;
      height: 70px;
    }

    &.-large {
      max-width: 100%;
      height: 140px;

      img {
        max-width: 200px;
      }
    }

  }
}