.c-image {

  margin: 0 auto;

  img {
    display: block;
    margin: 0 auto;
    // box-shadow: 0 8px 8px -8px rgba($black, .12);

    + img {
      margin-top: 1em;
    }
  }

  &.-large {
    max-width: 960px;
  }

  &.-small {
    max-width: 575px;
  }

  &.-w640 {
    max-width: 640px;
  }

  &.-w600 {
    max-width: 600px;
  }

  &__caption {
    margin-top: 1em;
    text-align: left;
  }
}