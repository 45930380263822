.c-accordion {
  $this: &;
  margin-top: -1px;

  &:last-of-type {
    border-bottom: 1px solid $base-border-color;
  }

  &:target {
    #{$this}__panel {
      display: block;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.75em 2em 1.75em 0;
    position: relative;
    border-top: 1px solid $base-border-color;
    line-height: 1.8;
    cursor: pointer;
  }

  &__faq-icon {
    margin-right: 1em;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    word-break: break-all;
  }

  &__title {
    font-size: 2rem;
    line-height: 1.5;
  }

  &__icon {
    @include centering(false, true);
    right: 0;
    @include transition;

    >span {
      position: absolute;
      right: 6px;
      width: 22px;
      height: 3px;
      background: $gray-darken-1;
      content: "";
      transition: 0.15s all;
    }

    >span:last-child {
      transform: rotate(90deg);
    }

    &.-active {
      >span:last-child {
        display: none;
      }
    }
  }

  &__panel {
    display: none;
    padding-bottom: 2em;

    &.-pb0 {
      padding-bottom: 0;
    }

    .c-accordion {
      padding-left: 1.5em;
      border-bottom: none;
    }
  }

  &__text-box {
    display: flex;
  }

  &__text {
    max-width: 940px;
  }

  @include media-tablet {
    &__icon {

      span {
        width: 18px;
        height: 2px;
      }

    }
  }

  @include media-sp {
    &__title {
      font-size: 1.4rem;
    }

    &__icon {
      top: 34px;
    }

    &__faq-icon {
      margin-right: .75em;
      font-size: 2.2rem;
    }
  }
}