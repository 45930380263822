.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: $white;
  text-align: center;
  transition: all .2s;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 14px;
    @include transition;
  }

  &__logo {
    width: 104px;

    img {
      @include transition;
    }
  }

  @include media-pc {
    height: 126px;

    &__menu-btn {
      display: none;
    }

    &__nav {
      display: block !important;
    }

    &__nav-close {
      display: none;
    }
  }

  @include media-pc-s {
    &__inner {
      padding-right: 14px;
    }
  }

  @include media-tablet {
    &__logo {
      width: 80px;
    }
  }

  @include media-pc {
    /* height: 126px; */
  }

  @include media-sp {
    background: $white;

    &__inner {
      height: 60px;
      padding: 4px 10px 4px 0;
    }

    &__logo {
      width: 60px;
    }

    &__nav {
      overflow-y: auto;
      display: none;
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, .95);
    }

    &__nav-wrap {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 6px;
    }

    &__nav-close {
      display: block;
      position: relative;
      width: 160px;
      margin: 24px auto 0;
      padding: 10px 16px;
      border-radius: 5px;
      border: 1px solid $gray-lighten-2;
      background: $white;
      cursor: pointer;

      &::after {
        position: absolute;
        right: 12px;
        color: $gray-darken-1;
        font-family: "nssol-icon";
        content: "\e908";
      }
    }
  }
}

/* ヘッダー縮小時スタイル
----------------------------------------------------------- */

.l-header.-shrinked {

  @include media-pc {
    height: 80px;

    .l-header__inner {
      height: 80px;
    }

    .l-header__logo {

      img {
        transform: scale(.75);
      }
    }

    .l-header-sub-nav {
      opacity: 0;
      height: 0;
      margin-top: 0;
    }

    .l-gnav {
      margin-top: 16px;
    }

    .l-gnav-menu {
      top: 80px;
    }
  }
}


/* l-header-menu-btn
----------------------------------------------------------- */

.l-header-menu-btn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 60px;
  background: $white;
  text-align: center;

  @include media-pc {
    display: none;
  }

  &__text {
    position: absolute;
    text-indent: -9999px;
  }

  &__icon {
    position: absolute;
    top: 20px;
    right: 11px;
  }

  &__line {
    display: inline-block;
    position: absolute;
    right: 0;
    height: 2px;
    background: $cyan;
    transition: all .3s;

    &:first-child {
      width: 27px;
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;
      width: 24px;
    }

    &:last-child {
      top: 18px;
      width: 20px;
    }
  }

  &.-active {
    .l-header-menu-btn__line {
      &:first-child {
        top: 8px;
        right: -2px;
        width: 26px;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        top: 8px;
        right: -2px;
        width: 26px;
        transform: rotate(45deg);
      }

      &:last-child {
        opacity: 0;
      }
    }
  }
}

/* l-header-search-btn
----------------------------------------------------------- */

.l-header-search-btn {

  @include media-pc {
    display: none;
  }

  @include media-sp {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 50px;
    width: 42px;
    height: 60px;
    background: $white;
    font-size: 2.2rem;

    .icon-search {
      span {
        position: absolute;
        text-indent: -9999px;
      }

      &::before {
        color: $cyan;
      }
    }
  }

}


/* l-header-sub-nav
----------------------------------------------------------- */

.l-header-sub-nav {
  @include transition;

  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__item {
    margin-left: 24px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    font-size: 1.4rem;

    [class^="icon-"] {
      margin-left: 4px;

      &::before {
        color: $cyan;
        font-size: 1.8rem;
      }
    }

    &:hover {
      color: $cyan;
    }

    &.-logo {
      display: flex;
      margin-top: -1px;

      img {
        width: 110px;
        vertical-align: middle;
      }
    }
  }

  &__inquiry-btn {
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4px 5px 5px 22px;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    background: $white;
    color: $base-font-color;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;
    @include transition;

    .icon-triangle-right {
      overflow: hidden;
      margin-left: 6px;
      color: $gray-darken-1;
      font-size: 1rem;

      &::before {
        vertical-align: middle;
        transform: translateX(0);
      }

      &::after {
        @include btn-cover;
      }
    }
  }

  @include media-pc {
    margin-top: 18px;

    &__item {
      display: flex;
      align-items: center;
    }

    &__inquiry-btn {
      &:hover {

        .icon-triangle-right {
          &::before {
            color: $yellow;
            @include arrow-slide;
          }

          &::after {
            @include btn-animation;
            background: rgba($black, .08);
          }
        }
      }
    }
  }

  @include media-sp {
    min-height: 320px;
    margin-top: 6px;
    padding: 0 14px;

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      margin: 14px 0 0;

      &:last-child {
        display: none;
      }
    }

    &__inquiry-btn {
      width: 100%;
      height: 44px;
      margin-bottom: 12px;
      font-size: 1.5rem;
    }
  }
}