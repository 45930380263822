.c-sentence {
  &.-large {
    font-size: 1.8rem;
  }

  &.-small {
    font-size: 1.4rem;
  }

  &.-xsmall {
    font-size: 1.2rem;
  }

  @include media-sp {
    &.-large {
      font-size: 1.6rem;
    }

    &.-small {
      font-size: 1.3rem;
    }

    &.-xsmall {
      font-size: 1.1rem;
    }
  }
}