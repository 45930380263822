/* stories-card
----------------------------------------------------------- */
.stories-card {
  $this: &;
  display: block;
  overflow: hidden;
  position: relative;

  &__link {
    &[target="_blank"] {
      #{$this}__text {
        &::after {
          display: inline-block;
          margin-left: 6px;
          color: #4A4A4A;
          font-family: 'nssol-icon';
          line-height: 1;
          content: "\e910";
        }
      }
    }
  }

  &__image {
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: 62%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      transition: all 0.2s ease-in-out;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(/assets/img/common/bg_link_stories_related.png);
      background-size: cover;
      content: "";
    }

    span {
      @include centering(false, true);
      padding: 8px 24px;
      color: $gray-darken-1;
      font-size: 6rem;
      line-height: 1;
      white-space: nowrap;

      &:first-letter {
        font-size: 16rem;
      }

      &::after {
        content: attr(data-title);
      }
    }

    &:empty {
      background: #000;
    }
  }

  &__summary {
    padding: 1em 1em 1.5em;
  }

  &__text {
    margin-bottom: 8px;
    color: $marine-blue;
    line-height: 1.7;
    overflow: hidden;
  }

  &__date {
    text-align: right;
    line-height: 1;
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 1em 0;

    &:last-child {
      padding-bottom: 1em;
    }

    >a {
      margin: 0 7px 7px 0;
    }
  }

  @include media-pc {
    background: $white;

    &__summary {
      background: $white;
    }

    &__category {
      background: $white;
    }

    &__text {
      min-height: 5em;
    }

    &__link {
      &:hover {
        .stories-card__text {
          text-decoration: underline;
        }

        img {
          transform: scale(1.07);
        }
      }
    }
  }

  @include media-sp {

    &__image {
      span {
        padding-left: 5px;
        font-size: 2.8rem;

        &:first-letter {
          font-size: 7rem;
        }
      }
    }

    &__summary {
      padding: .75em .75em 1em;
    }

    &__text {
      font-size: 1.2rem;
    }

    &__date {
      font-size: 1.1rem;
    }

    &__category {
      padding-left: .75em;

      >span {
        margin: 0 5px 5px 0;
      }
    }
  }
}

/* stories-related-links
----------------------------------------------------------- */

.stories-related-links {
  max-width: $base-content-width;
  margin: 0 auto;

  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: none;
    height: auto;
    background: #fff;
  }

  @include media-pc {
    &__item {
      width: 33%;
    }

    .slick-slide {
      margin: 0 8px;
    }
  }


  @include media-sp {
    width: 93%;
    margin-left: 0;

    .slick-list {
      overflow: inherit;
    }

    .slick-slide {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#f9f9f9, .9);
        content: "";
        transition: all .3s;
      }
    }

    .slick-active {
      &::after {
        background: transparent;
        opacity: 0;
        height: 0;
      }
    }
  }
}

/* stories-main
----------------------------------------------------------- */

.stories-main {
  padding-top: 80px;

  @include media-sp {
    padding-top: 42px;
  }
}


.stories-top-section {
  margin-bottom: 24px;

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1366px;
    margin: 0 auto;

    @include media-tablet {
      flex-direction: column;
    }
  }

  @include media-sp {
    margin-bottom: 0;
  }
}

/* stories-top-eyecatch
----------------------------------------------------------- */

.stories-top-eyecatch {
  width: 60%;

  &__image {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      transition: all 0.2s ease-in-out;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .c-link-label {
      margin-right: 8px;
    }
  }

  &__date {
    margin-right: 1em;
  }


  &__title {
    margin-bottom: .25em;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__sub-title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }

  @include media-tablet {
    width: 100%;
  }

  @include media-pc {

    &__image {
      &:hover {
        img {
          transform: scale(1.07);
        }
      }
    }

    &__summary {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1.5em 2em;
      background: rgba($white, .8);
      z-index: 21;
    }

    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1024px) {
    &__summary {
      position: relative;
      top: 100%;
    }
  }

  @include media-sp {
    &__summary {
      padding: 16px;
    }

    &__info {
      margin-bottom: 14px;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__sub-title {
      font-size: 1.4rem;
    }
  }
}

/* stories-top-search
----------------------------------------------------------- */

.stories-top-search {
  width: 35%;
  padding: 3em;

  &__keywords {
    margin-bottom: 1.5em;
  }

  &__rss {
    &::after {
      padding-left: 6px;
      color: $cyan;
      font-size: 1.6rem;
      font-family: 'nssol-icon' !important;
      content: "\e906";
    }
  }

  @include media-pc {
    min-width: 500px;
    &__rss {
      font-size: 1.8rem;

      &:hover {
        color: $cyan;
      }
    }
  }

  @include media-tablet {
    width: 100%;
  }

  @include media-sp {
    padding: 16px 16px 0;
  }
}

/* stories-list
----------------------------------------------------------- */

.stories-list {
  @include media-pc {
    > li {
      &:nth-child(n + 4) {
        margin-top: 2em;
      }
    }
  }


  @include media-sp {
    &.c-grid {
      margin: -1.5em 0 0;
    }

    > li {

      &.c-grid__item {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

/* stories-article-hero
----------------------------------------------------------- */

.stories-article-hero {
  overflow: hidden;
  position: relative;
  max-width: 1366px;
  margin: 0 auto;
  max-height: 720px;

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .c-link-label {
      margin-right: 8px;
    }
  }

  &__date {
    margin-right: 1em;
  }

  &__sns {
    position: absolute;
    right: 0;
    display: flex;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 4.4rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__sub-title {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.4;
  }

  @include media-pc {

    &__summary {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2em;
      background: rgba($white, .8);
    }

    &__link {
      &:hover {
        color: $marine-blue;
        text-decoration: underline;
      }
    }
  }

  @include media-tablet {
  }

  @include media-sp {

    &__image {
      margin-bottom: 14px;
    }

    &__summary {
      padding: 16px 16px 0;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__sub-title {
      font-size: 1.4rem;
    }

    &__sns {
      top: -86px;
    }
  }
}


.stories-article {

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__sns-btn {
    display: block;
    width: 36px;
    height: 36px;

    span {
      @include sr-only;
    }

    &:last-child {
      margin-left: 1em;
    }

    @include media-pc {
      &:hover {
        opacity: .7;
      }
    }
  }

  &__body {
    p {
      margin: 2em 0;

      &:first-child {
        margin-top: 0;
      }
    }

    .article-profile,
    .article-logo-box p {
      margin: 0;
    }

    .image-block {
      margin: 3em -65px;
    }

    .interview {
      font-weight: 500;

      + .answer {
        margin-top: 8px;
      }
    }

    .answer {
      .name {
        font-weight: 500;
      }
    }
  }
}