.sust-new-topics {
  display: flex;
  align-items: center;
  padding: 1.5em 0;
  border-top: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;

  &__date {
    width: 130px;
  }

  @include media-sp {
    flex-direction: column;
    align-items: flex-start;
    padding: 1em 0;

    &__date {
      margin-bottom: 4px;
    }
  }
}


.sust-top-link-list {

  @include media-pc {
    > li {
      &:nth-child(n + 3) {
        margin-top: 3em;
      }
    }
  }
}

.sust-topics-list {

  @include media-pc {
    > li {
      &:nth-child(n + 4) {
        margin-top: 3em;
      }
    }
  }

  @include media-sp {
    &.c-grid {
      margin: -1.5em 0 0;
    }

    .c-grid__item {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.sust-esg-torikumi {
  $this: &;
  display: flex;
  margin-bottom: 3em;

  &__sdgs {
    margin-top: 3em;
  }

  &__sdgs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  &__sdgs-item {
    width: 110px;
    margin: 8px;
  }

  @include media-pc {
    &__block {
      width: 50%;
      padding-right: 1.75em;

      + #{$this}__block {
        padding-right: 0;
        padding-left: 1.75em;
        border-left: 1px solid #D4D4D4;
      }
    }
  }

  @include media-sp {
    flex-direction: column;
    margin-bottom: 2em;

    &__block {
      + #{$this}__block {
        margin-top: 32px;
      }
    }

    &__sdgs {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
    }

    &__sdgs-list {
      width: 200px;
    }

    &__sdgs-item {
      width: 80px;
    }
  }
}