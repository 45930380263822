.l-future {
  height: 350px;
  padding: 0 16px;
  border-top: 1px solid #CECECE;
  border-bottom: 1px solid #CECECE;
  background: url(/assets/img/common/bg_future_block.png) no-repeat center/cover;


  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $base-content-width;
    margin: 0 auto;
    height: 350px;
  }

  &__title {
    width: 70%;
    margin-right: 24px;
    margin-left: -4%;
  }

  @media (max-width: 1340px) {
    &__title {
      margin-left: 0;
    }
  }

  @include media-sp {
    padding: 24px 16px 32px;
    height: auto;

    &__inner {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }

    &__title {
      width: 90%;
      margin-bottom: 24px;
    }

  }
}