.l-pagetitle {
  padding: 64px 16px 72px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__heading {
    font-size: 4.8rem;
    font-weight: 700;
    color: $white;
    text-shadow: 0 0 4px rgba(#3B4B58, .8);
    line-height: 1.4;
  }

  @include media-sp {
    padding: 24px 16px 32px;

    &__inner {
      align-items: flex-start;
      flex-direction: column;

      .c-btn {
        margin-top: 24px;
      }
    }

    &__heading {
      font-size: 2.6rem;
    }
  }

  &.-press {
    background: url(/assets/img/press/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-seminar {
    background: url(/assets/img/seminar/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-casestudy {
    background: url(/assets/img/casestudy/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-ss-top {
    background: url(/assets/img/ss/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-production {
    background: url(/assets/img/ss/production/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/production/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-circulation {
    background: url(/assets/img/ss/circulation/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/circulation/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-service_industry {
    background: url(/assets/img/ss/service_industry/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/service_industry/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-lifescience {
    background: url(/assets/img/ss/lifescience/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/lifescience/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-iox {
    background: url(/assets/img/ss/iox/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/iox/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-telecom {
    background: url(/assets/img/ss/telecom/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/telecom/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-public {
    background: url(/assets/img/ss/public/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/public/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-consulting {
    background: url(/assets/img/ss/consulting/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/consulting/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-application {
    background: url(/assets/img/ss/application/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/application/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-mediumsized {
    background: url(/assets/img/ss/mediumsized/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/mediumsized/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-bigdata {
    background: url(/assets/img/ss/bigdata/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/bigdata/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-infrastructure {
    background: url(/assets/img/ss/infrastructure/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/infrastructure/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-finance {
    background: url(/assets/img/ss/finance/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/finance/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-ito {
    background: url(/assets/img/ss/ito/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/ito/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ss-global {
    background: url(/assets/img/ss/global/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/global/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-ir-top {
    background: url(/assets/img/ir/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-sustainability-top {
    background: url(/assets/img/sustainability/bg_pagetitle.jpg) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/sustainability/bg_pagetitle_sp.jpg) no-repeat center center / cover;
    }
  }

  &.-corporate-top {
    background: url(/assets/img/corporate/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-recruit-top {
    background: url(/assets/img/recruit/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-ss-5g {
    background: url(/assets/img/ss/5g/bg_pagetitle.jpg) no-repeat center center / cover;
  }

  &.-ss-newnormal {
    background: url(/assets/img/ss/newnormal/bg_pagetitle.png) no-repeat center center / cover;
    @include media-sp {
      background: url(/assets/img/ss/newnormal/bg_pagetitle_sp.png) no-repeat center center / cover;
    }
  }

}