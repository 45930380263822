
.press-article {
  /*others.css*/

/* [text block & img block] left right */
.txt-block{
	width:370px;
	float:left;
	display:inline;
}
.img-block{
	width:160px;
	float:left;
	display:inline;
}
.mr-15{
	margin-right:15px!important;
}

.cf:after{
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.cf{
  min-height: 1px;
  height: 1px;
}

/*table.css*/
.bgno {
	background: none !important;
}

/* 9. Table 130128追加
========================================== */
/* 10.download Table
========================================== */
.Checkbox{
	border: 1px solid #dddddd;
}

.Checkbox th{
	background-color: #e6e6e6;
	border-bottom: 1px solid #dddddd;
}
.Checkbox td{
	padding: 0 6px;
	border-bottom: 1px solid #dddddd;
}

/*list.css*/

	/* 1-12.Get Windows Media Player
	---------------------------------------- */
	#cl_sub_content ul.get-mediaplayer {
		width: 600px;
		margin:15px auto;
		padding: 0 10px;
		border: 1px solid #EDEDED;
	}

	#cl_sub_content ul.get-mediaplayer li {
		display: table;
		margin: 0; /* Reset */
		padding: 10px 0;
		background: url(../../img/common/border_dotted_gray_01.gif) repeat-x;
	}

	#cl_sub_content ul.get-mediaplayer li.first {
		background: none;
	}

	#cl_sub_content ul.get-mediaplayer li p {
		display: table-cell;
		margin: 0; /* Reset */
		padding-right: 10px;
		vertical-align: middle;
		font-size: 90%;
		line-height: 1.5;
	}

	#cl_sub_content ul.get-mediaplayer li p a {
		padding-bottom: 1px; /* for Underline */
	}

	#cl_sub_content ul.get-mediaplayer li p.text {
		padding: 0;
		width: 480px;
	}

	/* IE6 Only */
	* html #cl_sub_content ul.get-mediaplayer li p {
		display: inline;
		zoom: 1;
	}

	/* IE7 Only */
	*:first-child + html #cl_sub_content ul.get-mediaplayer li p {
		display: inline;
		zoom: 1;
  }


  /*form.css*/
  /*formpage.css*/

}
.press-article .txt-block{
  width: calc(100% - 180px);
  float:left;
  display:inline;
}
.press-article .img-block{
  width:160px;
  float:left;
  display:inline;
}
.press-article .mr-15{
  margin-right:15px!important;
}
.press-article .cf:after{
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.press-article .layout1 {
  margin-bottom: 30px;
}
.press-article .cf,
.press-article ul,
.press-article ol,
.press-article table {
  margin: 30px 0 ;
}
.press-article p,
.press-article p:last-child  {
  margin: 0 0 1.2em ;
}
.press-article__footer {
  a {
    + p {
      margin-top: 1.2em;
    }
  }
}

.press-article__footer > div:first-child:not(.c-box){
    margin-bottom: 64px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    p{
      margin-bottom: 30px;
    }
}
