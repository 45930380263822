.c-card {
  display: block;
  overflow: hidden;
  position: relative;

  &__image {
    overflow: hidden;
    position: relative;
    z-index: 0;
    height: 0;
    padding-bottom: 62%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      transition: all 0.2s ease-in-out;
    }

    &::before {
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      background-image: url(/assets/img/common/bg_link_stories_related.png);
      background-size: cover;
      content: "";
    }

    span {
      @include centering(false, true);
      z-index: -1;
      padding: 8px 24px;
      color: $gray-darken-1;
      font-size: 6rem;
      line-height: 1;
      white-space: nowrap;

      &:first-letter {
        font-size: 16rem;
      }

      &::after {
        content: attr(data-title);
      }
    }

    &:empty {
      background: #000;

    }
  }

  &__summary {
    padding: 1em 1em 1.5em;
  }

  &__text {
    margin-bottom: 8px;
    color: $marine-blue;
    line-height: 1.7;
    overflow: hidden;
  }

  &__date {
    text-align: right;
    line-height: 1;
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 1em 1em;

    >a {
      margin: 0 7px 7px 0;
    }
  }

  @include media-pc {

    &__summary {
      background: $white;
    }

    &__category {
      background: $white;
    }

    &__text {
      min-height: 5em;
    }

    &__link {
      &:hover {
        .c-card__text {
          text-decoration: underline;
        }

        img {
          transform: scale(1.07);
        }
      }
    }
  }

  @include media-sp {

    &__image {
      span {
        padding-left: 5px;
        font-size: 2.8rem;

        &:first-letter {
          font-size: 7rem;
        }
      }
    }

    &__summary {
      padding: .75em .75em 1em;
    }

    &__text {
      font-size: 1.2rem;
    }

    &__date {
      font-size: 1.1rem;
    }

    &__category {
      padding-left: .75em;

      >span {
        margin: 0 5px 5px 0;
      }
    }
  }
}