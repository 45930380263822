.l-lnav {
  padding: 72px 16px;

  &__inner {
    max-width: $base-content-width;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 1.25em;
    font-size: 2.4rem;
    font-weight: 500;

    .icon-triangle-right {
      overflow: hidden;
      position: relative;
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-left: 10px;

      &::before {
        position: absolute;
        top: 4px;
        color: $gray-darken-1;
        font-size: 1.8rem;
        transform: translateX(0);
        @include transition;

        @include media-tablet {
          top: 10px;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5em;
  }

  &__item {
    width: 20%;
    padding: 0.5em;

    &.-current {
      .c-btn {
        pointer-events: none;

        &__icon {
          &::after {
            color: $yellow;
          }
        }
      }
    }

    a {
      height: 100%;
    }
  }

  @include media-pc {

    &__title {
      &:hover {

        .icon-triangle-right {

          &::before {
            color: $yellow;
            @include arrow-slide;
          }
        }
      }
    }
  }

  @include media-tablet {
    &__item {
      width: 33.3%;
    }
  }

  @include media-sp {
    padding: 32px 16px;

    &__title {
      font-size: 1.6rem;

      .icon-triangle-right {
        width: 16px;
        height: 16px;

        &::before {
          top: 4px;
          font-size: 1.2rem;
        }
      }
    }

    &__item {
      width: 50%;
    }
  }
}